import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Home from '../views/Home.vue'
import About from '../views/about.vue'
import Privacy from '../views/privacy.vue'
import Terms from '../views/terms.vue'
import Return from '../views/return.vue'
import Disclaimer from '../views/disclaimer.vue'
import Login from '@/views/auth/login'
import Register from '@/views/auth/register'
import ForgotPassword from '@/views/auth/forgot-password'
import ResetPassword from '@/views/auth/reset-password'
import Offers from '@/views/offers'
import Faqs from '@/views/faqs'
import Contact from '@/views/contact'
import CategoryId from '@/views/categories/id'
import StoreId from '@/views/stores/id'
import ProductId from '@/views/products/id'

//test
import TestPage from '@/views/stores/test'

// User
import User from '@/views/user'
import Dashboard from '@/views/user/dashboard'
import Orders from '@/views/user/orders'
import Wallet from '@/views/user/wallet'
import Address from '@/views/user/address'
import Rewards from '@/views/user/rewards'

import Checkout from '@/views/checkout'
import Payment from '@/views/payment'
import ThankYou from '@/views/thank-you'

//product customization
import ProductCustomizationPage from "@/views/product-customization/index.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tests-page',
    name: 'TestPage',
    component: TestPage
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/return',
    name: 'Return',
    component: Return
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        return next({
          name: 'Home'
        })
      }
      next()
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        return next({
          name: 'Home'
        })
      }
      next()
    },
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: Faqs
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/categories/:categoryId',
    name: 'CategoryId',
    component: CategoryId
  },
  {
    path: '/categories/:categoryId/stores/:storeId',
    name: 'StoreId',
    component: StoreId
  },
  {
    path: '/categories/:categoryId/stores/:storeId/products/:productId',
    name: 'ProductId',
    component: ProductId,
    props: true
  },
  //product customization

  {
    path: "/product-customizations",
    name: "ProductCustomizationPage",
    component: ProductCustomizationPage,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    // beforeEnter: (to, from, next) => {
    //   if(!store.getters['auth/authenticated']) {
    //     return next({
    //       name: 'Login'
    //     })
    //   }
    //   next()
    // },
  },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: ThankYou,
    // beforeEnter: (to, from, next) => {
    //   if(!store.getters['auth/authenticated']) {
    //     return next({
    //       name: 'Login'
    //     })
    //   }
    //   next()
    // },
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    children: [
      {
        path: '/user/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/user/orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: '/user/wallet',
        name: 'Wallet',
        component: Wallet
      },
      {
        path: '/user/address',
        name: 'Address',
        component: Address
      },
      {
        path: '/user/rewards',
        name: 'Rewards',
        component: Rewards
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

export default router
