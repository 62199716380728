var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-9 col-md-8"},[_c('div',{staticClass:"dashboard-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_vm._m(0),_c('div',{staticClass:"welcome-text"},[_c('h2',[_vm._v("Hi! "+_vm._s((_vm.user.first_name || '') + ' ' + (_vm.user.middle_name || '') + ' ' + (_vm.user.last_name || '')))])])]),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{staticClass:"pdpt-bg"},[_vm._m(1),_c('div',{staticClass:"ddsh-body"},[_c('h2',[_vm._v("6 Rewards")]),_c('ul',[_c('li',[_c('router-link',{staticClass:"small-reward-dt hover-btn",attrs:{"to":"/user/rewards"}},[_vm._v("Won $2")])],1),_c('li',[_c('router-link',{staticClass:"rewards-link5",attrs:{"to":"/user/rewards"}},[_vm._v("+More")])],1)])]),_c('router-link',{staticClass:"more-link14",attrs:{"to":"/user/rewards"}},[_vm._v("Rewards and Details "),_c('i',{staticClass:"uil uil-angle-double-right"})])],1)]),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{staticClass:"pdpt-bg"},[_vm._m(2),_vm._m(3),_c('router-link',{staticClass:"more-link14",attrs:{"to":"/user/orders"}},[_vm._v("All Orders "),_c('i',{staticClass:"uil uil-angle-double-right"})])],1)]),_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"pdpt-bg"},[_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"more-link14",attrs:{"to":"/user/rewards"}},[_vm._v("Rewards and Details "),_c('i',{staticClass:"uil uil-angle-double-right"})])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title-tab"},[_c('h4',[_c('i',{staticClass:"uil uil-apps"}),_vm._v("Overview")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdpt-title"},[_c('h4',[_vm._v("My Rewards")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdpt-title"},[_c('h4',[_vm._v("My Orders")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ddsh-body"},[_c('h2',[_vm._v("2 Recently Purchases")]),_c('ul',{staticClass:"order-list-145"},[_c('li',[_c('div',{staticClass:"smll-history"},[_c('div',{staticClass:"order-title"},[_vm._v("2 Items "),_c('span',{attrs:{"data-inverted":"","data-tooltip":"2kg broccoli, 1kg Apple","data-position":"top center"}},[_vm._v("?")])]),_c('div',{staticClass:"order-status"},[_vm._v("On the way")]),_c('p',[_vm._v("$22")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdpt-title"},[_c('h4',[_vm._v("My Wallet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wllt-body"},[_c('h2',[_vm._v("Credits $100")]),_c('ul',{staticClass:"wallet-list"},[_c('li',[_c('a',{staticClass:"wallet-links14",attrs:{"href":"#"}},[_c('i',{staticClass:"uil uil-card-atm"}),_vm._v("Payment Methods")])]),_c('li',[_c('a',{staticClass:"wallet-links14",attrs:{"href":"#"}},[_c('i',{staticClass:"uil uil-gift"}),_vm._v("3 offers Active")])])])])
}]

export { render, staticRenderFns }