<template>
  <section>
    <Filters />

    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"> Home </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="'/categories/' + category.id" class="see-more-btn">
                      {{ category.name }}
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ store.name }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="gambo-Breadcrumb" v-if="MobileSize">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb" class="d-flex justify-content-around p-1 sort-filter-class">
                <span @click="showSortModal = true">
                  <b-icon icon="sort-alpha-down-alt"></b-icon> Sort
                </span>
                <span v-b-modal.modal-center>
                  <b-icon icon="funnel"></b-icon>
                  Filter
                </span>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- Sort Modal -->
      <b-modal v-model="showSortModal" title="Sort By" @ok="sortOk">
        <b-form-group class="radio-class">
          <b-form-radio-group>
            <b-form-radio v-model="sort_type" v-for="(sort, i) in sortItems" :key="i" :value="sort.value">{{ sort.text
              }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-modal>
      <!-- <b-overlay :show="isLoading" rounded="lg" opacity="0.4"> -->
      <div class="all-product-grid">
        <div class="container-fluid">
          <!-- <b-row> -->
          <!-- <b-col lg="10" cols="6">
              <b-col lg="2" sm="12" cols="12" style="padding-left: 0px">
                <b-form-select v-model="sort_type" :options="sortItems" @change="sortProducts"></b-form-select>
              </b-col>
            </b-col> -->
          <div class="product-list-view">
            <b-row>
              <div class="col-lg-2" id="side-bar-container">
                <div class="sidebar-container overflow-x-auto" v-if="!MobileSize">
                  <sidebar-menu class="sticky-sidebar" :menu="menu" :show-child="true" theme="white-theme"
                    @item-click="onItemClick" />
                </div>
              </div>
              <b-col class="py-2" cols="12" xl="10" lg="10" sm="12" xs="12"
                v-if="products.length > 0 && menu.length > 0" style="background: white">
                <div class="products-container" id="infinite-list">
                  <b-row>
                    <b-col cols="6" xl="2" lg="3" md="4" sm="6" xs="6" class="product-card-class"
                      style="margin-bottom: 18px" v-for="(product, s) in products" :key="`product${s}`">
                      <div style="
                          position: fixed;
                          bottom: 20px;
                          right: 20px;
                          z-index: 1000;
                        " v-if="toasts[product.id]">
                        <div class="alert alert-success" role="alert">
                          <img style="width: 50px; height: 50px" :src="product.imagepath1
                            ? `${mediaURL}${product.imagepath1}`
                            : '/images/product/img-1.jpg'
                            " />
                          <br />
                          {{ product.product_price.pack_value }}
                          {{ product.product_price.unit }} of
                          {{ product.name }}
                          <br />
                          <strong> Added To Cart</strong>
                        </div>
                      </div>
                      <div class="product-item" style="height: 352px">
                        <router-link :to="`/categories/${store.category_id}/stores/${store.id}/products/${product.id}`"
                          class="product-img">
                          <b-skeleton-img v-if="!isImageLoaded[product.id]" height="200px"></b-skeleton-img>
                          <img
                            @load="loadImage(product.id, `${'https://ik.imagekit.io/edefqapdy/'}${product.imagepath1}`)"
                            :src="isImageLoaded[product.id] ? compressedImageUrls[product.id] : '/images/product/img-1.jpg'"
                            v-show="isImageLoaded[product.id]" alt="" style="height: 200px" />
                          <div class="product-absolute-options">
                            <span class="offer-badge-1" v-if="product.product_price.offer_percent != 0 ||
                              product.product_price.cashback_price
                            ">
                              <div align="start">
                                <img :src="product.sub_2_category.imagepath
                                  ? `${mediaURL}${product.sub_2_category.imagepath}`
                                  : '/images/product/img-1.jpg'
                                  " style="width: 85px" alt="" />
                              </div>
                            </span>
                          </div>
                        </router-link>
                        <div class="product-text-dt">
                          <h4 class="mb-0">
                            {{
                              product.name
                            }}
                          </h4>
                          <h4 class="mb-0">
                            {{ (product.sub_3_category
                              ? product.sub_3_category.name
                              : "") }}

                            {{ product.catelog_no || "" }}
                          </h4>
                          <div class="product-price" v-if="product.product_price.offer_price !=
                            product.product_price.price
                          ">
                            ₹
                            {{ product.product_price.offer_price | twoDecimal }}
                            <span class="real-price">₹
                              {{ product.product_price.price | twoDecimal }}
                            </span> <br>
                            <span class="off-percent ml-0" v-if="product.product_price.offer_percent != 0">
                              {{ product.product_price.offer_percent || 0 }}%
                              OFF
                            </span>
                          </div>
                          <div class="product-price" v-else>
                            ₹ {{ product.product_price.offer_price }}
                          </div>
                          <div class="qty-cart">
                            <div style="padding-right: 3px">
                              <select class="form-control" style="min-width: 3em !important" @change="
                                updateProductPriceByDropDownFrontEnd(
                                  product,
                                  product.selectedProductPriceId
                                )
                                " v-model="product.selectedProductPriceId">
                                <option v-for="(price, p) in product.product_prices" :key="`price${p}`"
                                  :value="price.id">
                                  {{ price.product_pack.value }}
                                  {{ price.product_pack.unit.name }}
                                </option>
                              </select>
                            </div>
                            <div class="quantity buttons_added" v-if="order.order_details.find(
                              (d) => d.product_id == product.id
                            ) == null
                            ">
                              <span class="plus plus-btn add-to-card-btn" @click="increementProduct(0, product)">Add To
                                Cart</span>
                            </div>
                            <div class="quantity buttons_added" v-else>
                              <input type="button" value="-" class="minus minus-btn" @click="
                                decreementProduct(
                                  order.order_details.find(
                                    (d) =>
                                      d.product_id == product.id
                                  ),
                                  product
                                )
                                " />
                              <input type="number" step="1" :value="order.order_details.find(
                                (d) => d.product_id == product.id
                              ).quantity
                                " class="input-text qty text" />
                              <input type="button" value="+" class="plus plus-btn" @click="
                                increementProduct(
                                  order.order_details.find(
                                    (d) => d.product_id == product.id
                                  ).quantity,
                                  product
                                )
                                " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <infinite-loading @infinite="infiniteHandler">
                  </infinite-loading>
                </div>
                <!-- <b-col>
                    <b-pagination v-if="MobileSize" v-model="page" :total-rows="count" :per-page="rowsPerPage" pills
                      size="lg"></b-pagination>
                  </b-col> -->
              </b-col>
              <b-col cols="12" xl="10" lg="10" sm="12" xs="12" v-else-if="isLoading" class="loader-class">
                <img style="width: 100px" src="../../assets/loader.gif" alt="" />
              </b-col>
              <b-col cols="12" xl="10" lg="10" sm="12" xs="12" class="no-results-message" v-else>
                <img style="width: 100px" src="../../assets/not-found.png" alt="" />
              </b-col>
            </b-row>
          </div>
        </div>
        <b-modal id="modal-center" centered title="Filter" @ok="onOk" @cancel="onCancel" hide-header-close
          ref="myModal">
          <sidebar-menu class="sticky-sidebar" :menu="menu" theme="white-theme" @item-click="onItemClick" />
        </b-modal>
      </div>
      <!-- </b-overlay> -->
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex";
import Filters from "./filters";
import axios from "axios";
import {
  selectDefaultProductPrice,
  updateProductPriceByDropDown,
  addOrderDetail,
} from "@/helpers/index.js";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import { SidebarMenu } from "vue-sidebar-menu";
import InfiniteLoading from "vue-infinite-loading";
import debounce from "lodash.debounce";
// import Compressor from 'compressorjs';


export default {
  data() {
    return {
      category: {},
      store: {},
      products: [],
      fetchData: [],
      toasts: [],
      treeData: [],
      allProducts: [],
      menu: [],
      tags: [],
      page: 1,
      count: 0,
      PaginationLength: 0,
      rowsPerPage: 40,
      isLoading: true,
      showSortModal: false,
      MobileSize: false,
      sub_2_category_id: null,
      sub_3_category_id: null,
      isImageLoaded: [],
      allLoaded: false,
      selectedSort: "",
      compressedImageUrls: [],
      someDate: new Date().toLocaleString(),
      sort_type: "LATEST",
      sortItems: [
        { value: "LATEST", text: "Latest" },
        { value: "OLDEST", text: "Oldest" },
        { value: "Low to High Price", text: "Price -- Low to High" },
        { value: "High To Low Price", text: "Price -- High to Low" },
        { value: "TITLE", text: "Title" },
      ],
      corsProxy: "https://cors-anywhere.herokuapp.com/",
      imageUrl: "",
      imageQueue: [],
      isProcessingQueue: false,
      abortControllers: {},
    };
  },
  computed: {
    ...mapGetters({
      categories: "category/getCategories",
      mediaURL: "settings/getMediaUrl",
      order: "order/getOrder",
    }),
  },
  components: {
    Filters,
    SidebarMenu,
    InfiniteLoading,
  },
  watch: {
    "$route.query.keyword"(newValue, oldValue) {
      if (newValue != oldValue) {
        this.page = 1;
        this.products = [];
        this.getData();
      }
    },
  },
  async mounted() {
    this.MobileSize = window.innerWidth < 992;
    await this.getData();
    this.addEventListeners();
  },
  beforeDestroy() {
    console.log("Component is being destroyed. Cleaning up ongoing processes...");
    for (const productId in this.abortControllers) {
      if (this.abortControllers[productId]) {
        this.abortControllers[productId].abort(); // Abort the fetch request
      }
    }
    // Clear the image queue'
    this.imageQueue = [];
    this.isProcessingQueue = false;
    this.removeEventListeners();
  },
  methods: {
    async getImageSize(productId, imageUrl, quality = 0.6) {
      const abortController = new AbortController();
      this.abortControllers[productId] = abortController
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        await new Promise((resolve) => {
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Resize the canvas
            canvas.width = img.width * 0.5; // Adjust the scale as needed
            canvas.height = img.height * 0.5;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert canvas to blob
            canvas.toBlob((compressedBlob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64String = reader.result;
                this.$set(this.compressedImageUrls, productId, base64String);
                this.$set(this.isImageLoaded, productId, true);
                resolve(); // Resolve the promise once the image is processed
              };
              compressedBlob && reader.readAsDataURL(compressedBlob);
            }, 'image/jpeg', quality); // Set the quality
          };
        });
      } catch (error) {
        console.error("Error fetching or compressing image:", error);
      }
    },
    infiniteHandler($state) {
      if (this.allLoaded) {
        return;
      }
      if (this.page == 1) {
        this.page = 2;
      }
      this.getData().then(() => {
        if (this.fetchData.length > 0) {
          this.page += 1;
          $state.loaded();
        } else {
          this.allLoaded = true;
          $state.complete();
          return;
        }
      });
    },
    debouncedInfiniteHandler: debounce(function ($state) {
      this.infiniteHandler($state);
    }, 300),
    async getTags() {
      let tags = await axios.get("tags");
      this.tags = tags.data.data;
    },
    async getData(isAll) {
      for (const productId in this.abortControllers) {
        if (this.abortControllers[productId]) {
          this.abortControllers[productId].abort(); // Abort the fetch request
        }
      }
      // Clear the image queue'
      this.imageQueue = [];
      this.isProcessingQueue = false;
      if (isAll == "isAll") {
        this.products = [];
        this.page = 1;
      }
      this.isLoading = true;
      this.category = this.categories.find(
        (category) => category.id == this.$route.params.categoryId
      );
      let sub_category = "";
      if (this.$route.query.sub_category) {
        sub_category = this.categories[0].sub_categories_filter.find(
          (category) => category.name == this.$route.query.sub_category
        ).id;
      }
      if (this.$route.query.sub_category_id) {
        sub_category = this.$route.query.sub_category_id;
      }
      this.store = this.category.companies.find(
        (company) => company.id == this.$route.params.storeId
      );
      let search_keyword =
        this.$route.query.keyword != null ? this.$route.query.keyword : "";
      console.log('product page search_keyword', search_keyword);

      let sub_2_category_id =
        this.$route.query.sub_2_category_id != null
          ? this.$route.query.sub_2_category_id
          : this.sub_2_category_id != null
            ? this.sub_2_category_id
            : "";
      let sub_3_category_id =
        this.$route.query.sub_3_category_id != null
          ? this.$route.query.sub_3_category_id
          : this.sub_3_category_id != null
            ? this.sub_3_category_id
            : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&sub_category_id=" +
        sub_category +
        "&sub_2_category_id=" +
        sub_2_category_id +
        "&sub_3_category_id=" +
        sub_3_category_id +
        // "&searchAll=true" +
        "&search=" +
        search_keyword;
      let products;
      // if (this.prevPage != this.page) {
      products = await axios.get(`products?${query}`, {
        headers: {
          "company-id": this.store.id,
        },
      });
      //   this.prevPage = this.page;
      // }
      this.fetchData = products.data.data;
      this.count = products.data.count;
      ``;
      // let allProducts = products.data.AllProducts.filter(
      //   (allProducts) =>
      //     allProducts.is_active == 1 && allProducts.product_prices.length > 0
      // );
      products = products.data.data.filter(
        (product) => product.is_active == 1 && product.product_prices.length > 0
      );
      // adding price to each product which is the default pricing
      let toasts = [];
      products.forEach((product) => {
        selectDefaultProductPrice(product, this.order);
        toasts[product.id] = false;
      });
      // allProducts.forEach((product) => {
      //   selectDefaultProductPrice(product, this.order);
      //   toasts[product.id] = false;
      // });
      // if (this.MobileSize) {
      //   this.products = products;
      // } else {
      this.products = [...this.products, ...products];
      // }
      // this.allProducts = allProducts;
      this.toasts = toasts;

      let tags = await axios.get("tags");
      this.tags = tags.data.data;
      this.menu = [
        {
          header: true,
          title: "Filters",
          hiddenOnCollapse: true,
        },
        {
          href: null,
          title: this.tags[0].name,
        },
        {
          href: null,
          title: "All Products",
        },
      ];
      let subCategories;

      // Check if sub_category_id is not null or an empty string
      if (sub_category != null || sub_category != "") {
        const subCategory = this.category.sub_categories_filter.find(
          (item) => item.id == sub_category
        );
        subCategories = subCategory ? [subCategory] : [];
      } else {
        subCategories = this.category.sub_categories_filter;
      }
      subCategories.forEach((sub) => {
        let subCategory = {
          href: null,
          title: sub.name,
          attributes: {
            sub_category_id: sub.id,
          },
        };
        subCategory.child = [];
        sub.sub_2_categories.forEach((sub2) => {
          let sub2Category = {
            href: null,
            title: sub2.name,
            attributes: {
              sub_2_category_id: sub2.id,
            },
          };
          sub2Category.child = [];
          sub2.sub_3_categories.forEach((sub3) => {
            let sub3Category = {
              href: null,
              title: sub3.name,
              attributes: {
                sub_3_category_id: sub3.id,
              },
            };
            sub2Category.child.push(sub3Category);
          });
          subCategory.child.push(sub2Category);
        });
        this.menu.push(subCategory);
      });
      // this.isLoading = false;
    },
    sortProducts() {
      this.products.sort((a, b) => {
        if (this.sort_type == "TITLE") return a.name.localeCompare(b.name);
        if (this.sort_type == "LATEST") return b.id - a.id;
        if (this.sort_type == "OLDEST") return a.id - b.id;
        if (this.sort_type === "Low to High Price")
          return a.product_price.offer_price - b.product_price.offer_price;
        if (this.sort_type === "High To Low Price")
          return b.product_price.offer_price - a.product_price.offer_price;
      });
    },
    updateProductPriceByDropDownFrontEnd(product, priceId) {
      updateProductPriceByDropDown(product, priceId, this.order);
    },
    decreementProduct(orderDetail, product) {
      let orderDetailQuantity = orderDetail.quantity;
      if (orderDetailQuantity > 1) {
        product.product_price.selectedQuantity = orderDetailQuantity - 1;
        this.addOrderDetailFrontEnd(product);
      } else if (orderDetailQuantity > 0) {
        this.$store.dispatch("order/removeOrderDetail", orderDetail);
      }
    },
    increementProduct(orderDetailQuantity, product) {
      if (
        this.order.store.id == null ||
        this.order.store.id == "" ||
        this.order.store.id == this.store.id
      ) {
        product.product_price.selectedQuantity = orderDetailQuantity + 1;
        this.addOrderDetailFrontEnd(product);
      } else {
        alert(
          "Order from another store has already been added. Please remove the order and try again"
        );
      }
    },
    async addOrderDetailFrontEnd(product) {
      this.toasts[product.id] = true;
      let orderDetail = addOrderDetail(this.store, product);
      this.$store.dispatch("order/addOrderDetail", orderDetail);
      await new Promise((r) => setTimeout(r, 1000));
      this.toasts[product.id] = false;
    },
    preventHide(bvModalEvt) {
      bvModalEvt.preventDefault();
    },
    sortOk() {
      this.sortProducts();
    },
    onOk() {
      this.$refs.myModal.hide();
    },
    onCancel() {
      this.$refs.myModal.hide();
    },
    async loadImage(productId, imageUrl) {
      this.isLoading = true;
      const cleanedUrl = imageUrl.replace(/\/bb/, '');
      const encodedUrl = encodeURI(cleanedUrl);
      if (!encodedUrl.includes('/null')) {
        this.imageQueue.push({ productId, imageUrl: encodedUrl });
        if (!this.isProcessingQueue) {
          this.processImageQueue();
        }
      }
      // await this.getImageSize(productId, encodedUrl);
      // this.compressedImageUrls[productId] = compressedImage; // Store the compressed image URL
      // this.$set(this.isImageLoaded, productId, true);
      this.isLoading = false;
    },
    async processImageQueue() {
      this.isProcessingQueue = true;

      while (this.imageQueue.length > 0) {
        const { productId, imageUrl } = this.imageQueue.shift();
        await this.getImageSize(productId, imageUrl);
      }

      this.isProcessingQueue = false;
    },

    async onItemClick(event, item) {
      let sub_category;
      if (this.$route.query.sub_category_id) {
        sub_category = this.$route.query.sub_category_id;
      }
      let sub_2_category_id =
        this.$route.query.sub_2_category_id != null
          ? this.$route.query.sub_2_category_id
          : "";
      // let sub_3_category_id =
      //   this.$route.query.sub_3_category_id != null
      //     ? this.$route.query.sub_3_category_id
      //     : "";
      let query =
        "sub_category_id=" +
        sub_category +
        "&sub_2_category_id=" +
        sub_2_category_id;
      // "&sub_3_category_id=" +
      // sub_3_category_id;
      if (this.allProducts.length == 0) {
        let products = await axios.get(`products?${query}`, {
          headers: {
            "company-id": this.store.id,
          },
        });
        this.allProducts = products.data.data;
      }
      if (item.title == this.tags[0].name) {
        this.products = this.allProducts.filter((product) =>
          product.tags.find((tag) => tag.id == this.tags[0].id)
        );
        return;
      }
      if (item.title == "All Products") {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        if (searchParams.has("keyword")) {
          searchParams.delete("keyword");
          const newUrl = `${url.origin}${url.pathname
            }?${searchParams.toString()}`;
          window.history.replaceState({}, "", newUrl);
          this.$route.query.keyword = "";
        }
        this.sub_2_category_id = null;
        this.sub_3_category_id = null;
        let isAll = "isAll";
        this.getData(isAll);
        return;
      }
      let toasts = [];
      this.allProducts.forEach((product) => {
        selectDefaultProductPrice(product, this.order);
        toasts[product.id] = false;
      });
      if (item.attributes.sub_category_id) {
        this.products = this.allProducts.filter(
          (product) =>
            product.sub_category_id == item.attributes.sub_category_id
        );
      }
      if (item.attributes.sub_2_category_id)
        this.products = this.allProducts.filter(
          (product) =>
            product.sub_2_category_id == item.attributes.sub_2_category_id
        );
      this.sub_2_category_id = item.attributes.sub_2_category_id;
      if (item.attributes.sub_3_category_id)
        this.products = this.allProducts.filter(
          (product) =>
            product.sub_3_category_id == item.attributes.sub_3_category_id
        );
      this.sub_3_category_id = item.attributes.sub_3_category_id;
    },
    addEventListeners() {
      document
        .getElementById("side-bar-container")
        .addEventListener("wheel", this.scrollHandler);
      document
        .querySelector(".header.clearfix")
        .addEventListener("wheel", this.scrollHandler);
      document
        .querySelector(".breadcrumb")
        .addEventListener("wheel", this.scrollHandler);
    },
    removeEventListeners() {
      document
        .getElementById("side-bar-container")
        .removeEventListener("wheel", this.scrollHandler);
      document
        .querySelector(".header.clearfix")
        .removeEventListener("wheel", this.scrollHandler);
      document
        .querySelector(".breadcrumb")
        .removeEventListener("wheel", this.scrollHandler);
    },
    scrollHandler(event) {
      event.preventDefault();
      var productContainer = document.getElementById("infinite-list");
      var scrollAmount = event.deltaY;
      productContainer.scrollTop += scrollAmount;
    },
  },
};
</script>
<style scoped>
@media (max-width: 576px) {
  .offer-badge-1 img {
    height: 48px !important;
  }
}
</style>
<style>
.filter-button .btn-primary {
  background-color: #f5c209 !important;
  border-color: #f5c209 !important;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .products-container .row .col-lg-3 {
    flex: 0 0 19% !important;
    max-width: 20% !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

.filter-button .b-icon.bi {
  font-size: 34px !important;
}

.sort-filter-class .b-icon.bi {
  font-size: 20px !important;
}

.alert-success {
  z-index: 1000;
  color: white;
  background-color: green;
  border-color: #d6e9c6;
}

.v-sidebar-menu {
  position: unset;
  height: auto;
}

.v-sidebar-menu .vsm--toggle-btn {
  display: none;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.no-results-message {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-results-message p {
  margin: 0;
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.no-results-message .icon {
  font-size: 36px;
  margin-bottom: 15px;
  color: #999;
}

.product-card {
  border-radius: 50px;
}

.radio-class .custom-control-label::after {
  top: 0.3rem !important;
  left: -20px !important;
  width: 1rem !important;
  height: 1rem !important;
}

.radio-class .custom-control-inline {
  display: block !important;
}

.radio-class .custom-control-label {
  font-size: 17px !important;
  margin-top: 8px;
}
</style>
