<template>
  <!-- Body Start -->
  <div class="col-lg-9 col-md-8">
    <div class="dashboard-right">
      <div class="row">
        <div class="col-md-12">
          <div class="main-title-tab">
            <h4><i class="uil uil-apps"></i>Overview</h4>
          </div>
          <div class="welcome-text">
            <h2>Hi! {{ (user.first_name || '')  + ' ' + (user.middle_name || '') + ' ' + (user.last_name || '') }}</h2>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="pdpt-bg">
            <div class="pdpt-title">
              <h4>My Rewards</h4>
            </div>
            <div class="ddsh-body">
              <h2>6 Rewards</h2>
              <ul>
                <li>
                  <router-link to="/user/rewards" class="small-reward-dt hover-btn">Won $2</router-link>
                </li>
                <li>
                  <router-link to="/user/rewards" class="rewards-link5">+More</router-link>
                </li>
              </ul>
            </div>
            <router-link to="/user/rewards" class="more-link14">Rewards and Details <i class="uil uil-angle-double-right"></i></router-link>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="pdpt-bg">
            <div class="pdpt-title">
              <h4>My Orders</h4>
            </div>
            <div class="ddsh-body">
              <h2>2 Recently Purchases</h2>
              <ul class="order-list-145">
                <li>
                  <div class="smll-history">
                    <div class="order-title">2 Items <span data-inverted="" data-tooltip="2kg broccoli, 1kg Apple" data-position="top center">?</span></div>
                    <div class="order-status">On the way</div>
                    <p>$22</p>
                  </div>
                </li>
              </ul>
            </div>
            <router-link to="/user/orders" class="more-link14">All Orders <i class="uil uil-angle-double-right"></i></router-link> 
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="pdpt-bg">
            <div class="pdpt-title">
              <h4>My Wallet</h4>
            </div>
            <div class="wllt-body">
              <h2>Credits $100</h2>
              <ul class="wallet-list">
                <li>
                  <a href="#" class="wallet-links14"><i class="uil uil-card-atm"></i>Payment Methods</a>
                </li>
                <li>
                  <a href="#" class="wallet-links14"><i class="uil uil-gift"></i>3 offers Active</a>
                </li>
              </ul>
            </div>
            <router-link to="/user/rewards" class="more-link14">Rewards and Details <i class="uil uil-angle-double-right"></i></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Body End -->
</template>


<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
}
</script>