<template>
  <!-- Search Model Start-->
  <div id="search_model" class="header-cate-model main-gambo-model modal fade" tabindex="-1" role="dialog"
    aria-modal="false">
    <div class="modal-dialog search-ground-area" role="document">
      <div class="category-area-inner">
        <div class="modal-header">
          <button type="button" class="close btn-close" data-dismiss="modal" aria-label="Close">
            <i class="uil uil-multiply"></i>
          </button>
        </div>

        <div class="category-model-content modal-content">
          <div class="search-header">
            <!-- <form action="#"> -->
            <input type="search" placeholder="Search for products..." v-model="search_keyword"
              @input="searchSuggestions" @keydown.enter="updateSearch">
            <button><i class="uil uil-search"></i></button>
            <!-- </form> -->
          </div>
          <div class="search-by-cat" v-if="suggestions.length">
            <div class="text" style="margin-left: 88px;margin-top: 12px;font-size: 17px;" @click="updateSearch">
              {{ search_keyword }}
            </div>
            <router-link
              :to="`/categories/${categories[0].id}/stores/${categories[0].companies[0].id}/products/${suggestion.id}`"
              class="single-cat" v-for="(suggestion, i) in suggestions" :key="i">
              <div class="icon">
                <img :src="suggestion.imagepath1
              ? `${mediaURL}${suggestion.imagepath1}`
              : '/images/product/img-1.jpg'" alt="">
              </div>
              <div class="text" @click="closeModal">
                {{
              suggestion.name +
              " - " +
              (suggestion.sub_3_category
                ? suggestion.sub_3_category.name
                : "")
            }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Search Model End-->
</template>
<script type="text/javascript">
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    setting: {},
    suggestions: [],
    search_keyword: ""
  }),
  computed: {
    ...mapGetters({
      categories: "category/getCategories",
      mediaURL: "settings/getMediaUrl",
    }),
  },
  methods: {
    async searchSuggestions() {
      console.log('search suggest');
      this.suggestions = [];
      if (this.search_keyword.length > 0) {
        const keyword = this.search_keyword.toLowerCase();
        let products = await axios.get(`/products?search=${keyword}`, {
          headers: {
            "company-id": this.categories[0].companies[0].id,
          },
        });
        this.suggestions = products.data.data;
      } else {
        this.suggestions = [];
      }
    },
    updateSearch() {
      console.log(this.search_keyword);
      this.$emit('search', this.search_keyword);
      const closeButton = document.querySelector('.btn-close');
      if (closeButton) {
        closeButton.click();
      }
    },
    closeModal() {
      console.log('clicked');
      const closeButton = document.querySelector('.close.btn-close');
      if (closeButton) {
        closeButton.click();
      }
    }
  }
}
</script>