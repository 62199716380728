<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Redirecting to payment page</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="all-product-grid">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
              <div class="order-placed-dt">
                <html>
                  <head>
                    <title>Merchant Checkout Page</title>
                  </head>
                  <body>
                    <center>
                      <h1>
                        Please do not refresh this page...
                      </h1>
                    </center>
                    <form ref="form" method="post" :action="txn_url" name="f1">  
                      <div v-html="form_fields"></div>
                      <!-- <button class="next-btn16 hover-btn" type="submit">Pay Now</button> -->
                    </form>
                  </body>
                </html>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'
import checksum_lib from '@/helpers/paytm/checksum/checksum.js'

export default {
  data () {
    return {
      paytmConfig: {
        mid: "bdTPeP92533780231351",
        key: "WDQBMR7f2bez93Fs",
        website: "WEBSTAGING"
      },
      txn_url: 'https://securegw-stage.paytm.in/theia/processTransaction',
      form_fields: null,
    }
  },
  created() {
    let baseUrl = axios.defaults.baseURL;
    var params            = {};
    params['MID']           = this.paytmConfig.mid;
    params['WEBSITE']       = this.paytmConfig.website;
    params['CHANNEL_ID']      = 'WEB';
    params['INDUSTRY_TYPE_ID']  = 'Retail';
    params['ORDER_ID']      =  this.$route.query.orderId;
    params['CUST_ID']       = 'Customer001';
    params['TXN_AMOUNT']      = '2.00';
    params['CALLBACK_URL']    = baseUrl + '/saveCallback';
    params['EMAIL']       = 'abc@mailinator.com';
    params['MOBILE_NO']     = '9579862371';
    let _this = this
    checksum_lib.genchecksum(params, this.paytmConfig.key, function (err, checksum) {
      // var txn_url = "https://securegw-stage.paytm.in/theia/processTransaction"; // for staging
      // var txn_url = "https://securegw.paytm.in/theia/processTransaction"; // for production
      
      var form_fields = "";
      for(var x in params){
        form_fields += "<input type='hidden' name='"+x+"' value='"+params[x]+"' >";
      }
      form_fields += "<input type='hidden' name='CHECKSUMHASH' value='"+checksum+"' >";
      _this.form_fields = form_fields;
      
    });
  },
  mounted() {
    // window.onload = (event) => {
    //   console.log(event + 'page is fully loaded');
    // };
    // console.log('Hello 1')
    // window.addEventListener('load', function(event) {
    //   alert(2)
    //   console.log(event)
    //   console.log(1)
    // }.bind(this))
    // console.log('Hello 1')
    
    setTimeout(function () {
      document.forms["f1"].submit();
    }.bind(this), 2000)
      
    
  },
}
</script>