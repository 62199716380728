<template>
  <section>
    <div class="sign-inup">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sign-form">
              <div class="sign-inner">
                <div class="sign-logo" id="logo">
                  <img src="images/logo.svg" alt="">
                  <img class="logo-inverse" src="images/dark-logo.svg" alt="">
                </div>
                <div class="form-dt">
                  <div class="form-inpts checout-address-step">
                    <form @submit.prevent="passwordResetEmail">
                      <div class="form-title"><h6>Reset Password</h6></div>
                      <div class="form-group pos_rel">
                        <input id="phone[number]" name="phone" type="text" placeholder="Enter email" class="form-control lgn_input" required="" v-model="form.email" disabled>
                        <i class="uil uil-mobile-android-alt lgn_icon"></i>
                      </div>
                      <div class="form-group pos_rel">
                        <input id="password1" name="password1" type="password" placeholder="Enter Password" class="form-control lgn_input" required="" v-model="form.password">
                        <i class="uil uil-padlock lgn_icon"></i>
                      </div>
                      <div class="form-group pos_rel">
                        <input id="password1" name="password1" type="password" placeholder="Confirm Password" class="form-control lgn_input" required="" v-model="form.password_confirmation">
                        <i class="uil uil-padlock lgn_icon"></i>
                      </div>
                      <button class="login-btn hover-btn" type="submit" @click="store">Reset Password</button>
                    </form>
                  </div>
                  <div class="password-forgor">
                    <router-link to="/Login">Login Now</router-link>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  data () {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  mounted() {
    this.getEmail()
  },
  methods: {
    ...mapActions({
      logIn: 'auth/logIn'
    }),
    async getEmail() {
      let emailData = await axios.get('/reset_password/getEmail?token=' + this.$route.query.token)
      emailData = emailData.data.data
      this.form.email = emailData.email
    },
    async store() {
      await axios.post(`/reset_password`, this.form)
      alert("Password Updated");
    }
  }
}
</script>