<template>
    <section>

        <div class="heading">
            <h1>Infinite Scroll</h1>
            <h4>A simple infinite scroll example using Vue.js</h4>
        </div>

        <div class="container" id="app">

            <div class="list-group-wrapper">
                <transition name="fade">
                    <div class="loading" v-show="loading">
                        <span class="fa fa-spinner fa-spin"></span> Loading
                    </div>
                </transition>
                <div class="list-group" id="infinite-list">
                    <div class="list-group-item" v-for="(item, i) in products" :key="i">{{ item.name }}</div>
                </div>
            </div>

        </div>

    </section>
</template>

<script type="text/javascript">
import axios from "axios";
export default {
    data() {
        return {
            category: {},
            store: {},
            products: [],
            toasts: [],
            treeData: [],
            allProducts: [],
            menu: [],
            tags: [],
            page: 1,
            count: 0,
            PaginationLength: 0,
            rowsPerPage: 40,
            isLoading: false,
            someDate: new Date().toLocaleString(),
            sort_type: "LATEST",
            sortItems: [
                "LATEST",
                "OLDEST",
                "TITLE",
                "Low to High Price",
                "High To Low Price",
            ],
            loading: false,
            nextItem: 1,
            items: []
        }
    },
    mounted() {

        // Detect when scrolled to bottom.
        const listElm = document.querySelector('#infinite-list');
        listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                this.fetchMoreProducts();
            }
        });

        // Initially load some items.
        this.getData();

    },
    methods: {
        async fetchMoreProducts() {
            console.log('comed');
            // if (this.page < this.PaginationLength) {
            this.page++;
            await this.getData(this.page);
            // }
        },
        async getData() {
            console.log("entered data");
            this.isLoading = true;
            this.category = 1
            // this.store = this.category.companies.find(
            //     (company) => company.id == this.$route.params.storeId
            // );
            let search_keyword =
                this.$route.query.keyword != null ? this.$route.query.keyword : "";
            let query =
                "page=" +
                this.page +
                "&rowsPerPage=" +
                this.rowsPerPage +
                "&searchAll=true" +
                "&search=" +
                search_keyword;
            let products = await axios.get(`products?${query}`, {
                headers: {
                    "company-id": 1,
                },
            });
            this.count = products.data.count;
            products = products.data.data.filter(
                (product) => product.is_active == 1 && product.product_prices.length > 0
            );
            console.log('products', products);
            this.products = [
                ...this.products,
                ...products,
            ]
            console.log('products', this.products);
            console.log('this.products.length', this.products.length);

            this.isLoading = false;
        },
        loadMore() {
            console.log('entered here')
            /** This is only for this demo, you could 
              * replace the following with code to hit 
              * an endpoint to pull in more data. **/
            this.loading = true;
            setTimeout(e => {
                console.log(e);
                for (var i = 0; i < 20; i++) {
                    this.items.push('Item ' + this.nextItem++);
                }
                this.loading = false;
            }, 200);
            /**************************************/

        }
    }
}
</script>
<style scoped>
.container {
    padding: 40px 80px 15px 80px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 800px;
}

.heading {
    text-align: center;

    h1 {
        background: -webkit-linear-gradient(#fff, #999);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        text-align: center;
        margin: 0 0 5px 0;
        font-weight: 900;
        font-size: 4rem;
        color: #fff;
    }

    h4 {
        color: lighten(#5c3d86, 30%);
        text-align: center;
        margin: 0 0 35px 0;
        font-weight: 400;
        font-size: 24px;
    }
}

.list-group-wrapper {
    position: relative;
}

.list-group {
    overflow: auto;
    height: 50vh;
    border: 2px solid #dce4ec;
    border-radius: 5px;
}

.list-group-item {
    margin-top: 1px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid #dce4ec;
}

.loading {
    text-align: center;
    position: absolute;
    color: #fff;
    z-index: 9;
    background: $purple;
    padding: 8px 18px;
    border-radius: 5px;
    left: calc(50% - 45px);
    top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>