<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">{{ category.name }}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="all-product-grid">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="product-top-dt">
                <div class="product-left-title">
                  <h2>{{ category.name }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="product-list-view">
            <div class="row">
              <div class="col-lg-3 col-md-6"
                v-for="(store, s) in category.companies"
                :key="`store${s}`"
              >
                <Store :store="store" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import Store from '@/components/store'

export default {
  data () {
    return {
      category: {}
    }
  },
  computed: {
    ...mapGetters({
      categories: 'category/getCategories',
    })
  },
  components: {
    Store
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      console.log(this.$route.params.categoryId)
      let category = this.categories.find(category => category.id == this.$route.params.categoryId)
      this.category = category
    }
  }

}
</script>