import axios from 'axios'

export default {
  namespaced: true,
  state: {
     offers: []
  },
  getters: {
    getOffers(state) {
      return state.offers
    }
  },
  mutations: {
    SET_OFFERS(state, data) {
      state.offers = data
    }
  },
  actions: {
    async fetchOffers({ commit }) {
      let response = await axios.get(`/offers`)
      commit('SET_OFFERS', response.data.data)
    },
  }
}