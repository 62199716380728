<template>
  <section>
    <div class="sign-inup">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sign-form">
              <div class="sign-inner">
                <div class="sign-logo" id="logo">
                  <a href="index.html"><img src="images/logo.svg" alt="" /></a>
                  <a href="index.html"
                    ><img
                      class="logo-inverse"
                      src="images/dark-logo.svg"
                      alt=""
                  /></a>
                </div>
                <div class="form-dt">
                  <div class="form-inpts checout-address-step">
                    <div class="form-title"><h6>Sign Up</h6></div>
                    <span style="color: red">{{ errors.first_name }}</span>
                    <div class="form-group pos_rel">
                      <input
                        id="full[name]"
                        name="fullname"
                        type="text"
                        placeholder="Full name"
                        class="form-control lgn_input"
                        required=""
                        v-model="form.first_name"
                      />
                      <i class="uil uil-user-circle lgn_icon"></i>
                    </div>
                    <span style="color: red">{{ errors.email }}</span>
                    <div class="form-group pos_rel">
                      <input
                        id="email[address]"
                        name="emailaddress"
                        type="email"
                        placeholder="Email Address"
                        class="form-control lgn_input"
                        required=""
                        v-model="form.email"
                      />
                      <i class="uil uil-envelope lgn_icon"></i>
                    </div>
                    <span style="color: red">{{ errors.phone }}</span>
                    <div class="form-group pos_rel">
                      <input
                        id="phone[number]"
                        name="phone"
                        type="text"
                        placeholder="Phone Number"
                        class="form-control lgn_input"
                        required=""
                        v-model="form.phone"
                      />
                      <i class="uil uil-mobile-android-alt lgn_icon"></i>
                    </div>
                    <div class="form-group pos_rel">
                      <input
                        id="password1"
                        name="password1"
                        type="password"
                        placeholder="Password"
                        class="form-control lgn_input"
                        required=""
                        v-model="form.password"
                      />
                      <i class="uil uil-padlock lgn_icon"></i>
                      <span style="color: red">{{ errors.password }}</span>
                    </div>
                    <div class="form-group pos_rel">
                      <input
                        id="password2"
                        name="password2"
                        type="password"
                        placeholder="Re-enter Password"
                        class="form-control lgn_input"
                        required=""
                        v-model="form.password_confirmation"
                      />
                      <i class="uil uil-padlock lgn_icon"></i>
                      <span style="color: red">{{
                        errors.password_confirmation
                      }}</span>
                    </div>
                    <div class="form-group pos_rel">
                      <input
                        id="referal"
                        name="referal"
                        type="text"
                        placeholder="Referal Code (optional)"
                        class="form-control lgn_input"
                        required=""
                        v-model="form.referral_code"
                      />
                      <i class="uil uil-money-bill lgn_icon"></i>
                      <span style="color: red">{{ errors.refer_user_id }}</span>
                    </div>
                    <button class="login-btn hover-btn" @click="store">
                      Sign Up Now
                    </button>
                    <span v-if="loading">Signing...</span>
                  </div>
                  <div class="signup-link">
                    <p>I have an account? - <a href="/login">Sign In Now</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="verification-modal"
      ref="verificationModal"
      title="Verify Email"
      hide-footer
    >
      <b-overlay
        :show="otpVeirifyLoading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <div
          class="d-flex flex-column align-items-center"
          style="line-height: 6"
        >
          <vue-otp-input
            :value="otp"
            @on-change="handleOtpChange"
            length="4"
            input-classes="otp-input"
            separator="-"
          ></vue-otp-input>
          <span style="color: red">{{ errorMessage }}</span>
          <b-button @click="verifyOtp" style="background-color: #f55d2c"
            >Verify</b-button
          >
        </div>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from "vuex";
import VueOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
export default {
  components: {
    VueOtpInput,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        role_id: 6,
        active: 1,
        referral_code: "",
      },
      otp: "",
      errorMessage: "",
      loading: false,
      otpVerified: false,
      otpVeirifyLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      errors: "errors/getErrors",
    }),
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async store() {
      this.loading = true;
      let referralCodeParts = this.form.referral_code.split(" ");
      let referralUserId = referralCodeParts[
        referralCodeParts.length - 1
      ].replace("REFX", "");
      this.form.refer_user_id = referralUserId;
      if (this.form.email != "" && this.otpVerified == false) {
        this.$refs.verificationModal.show();
        await axios.post(`/send_register_otp`, this.form);
      } else {
        let response = await axios
          .post(`/register-user`, this.form)
          .catch(() => {
            this.loading = false;
          });
        this.loading = false;
        // alert("Registered Successfully");
        if (response.data.data != null) {
          await this.login();
          this.$router.push(`/checkout`);
        }
      }
    },
    async verifyOtp() {
      this.otpVeirifyLoading = true;
      const payload = {
        email: this.form.email,
        otp: this.otp,
      };
      let response = await axios.post(`/verify_register_otp`, payload);
      if (response.data.success == true) {
        this.otpVerified = true;
        await this.store();
        this.$refs.verificationModal.hide();
        this.otpVeirifyLoading = false;
      } else {
        console.log('res[pmse]' , response);
        
        this.errorMessage = response.data.message;
        this.otpVeirifyLoading = false;
      }
    },
    async login() {
      const ipInfo = await axios.get("http://ip-api.com/json/");
      const deviceInfo = navigator.userAgent;
      this.form.device_name = this.getDeviceName(deviceInfo);
      this.form.location = ipInfo.data.city;
      this.form.login_time = new Date().toLocaleString();
      await this.logIn(this.form);
      this.$router.replace({
        name: "Home",
      });
    },
    getDeviceName(userAgent) {
      if (userAgent.match(/(iPhone|iPod|iPad)/)) {
        return "Apple Device";
      } else if (userAgent.match(/Android/)) {
        return "Android Device";
      } else if (userAgent.match(/Windows/)) {
        return "Windows PC";
      } else if (userAgent.match(/Macintosh/)) {
        return "Macintosh";
      } else {
        return "Unknown Device";
      }
    },
    handleOtpChange(otp) {
      this.otp = otp;
      console.log("OTP received:", this.otp);
    },
  },
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
