<template>
  <!-- Category Model Start-->
  <div id="category_model" class="header-cate-model main-gambo-model modal fade" tabindex="-1" role="dialog" aria-modal="false">
    <div class="modal-dialog category-area" role="document">
      <div class="category-area-inner">
        <div class="modal-header">
          <button type="button" class="close btn-close" data-dismiss="modal" aria-label="Close">
            <i class="uil uil-multiply"></i>
          </button>
        </div>
        <div class="category-model-content modal-content"> 
          <div class="cate-header">
            <h4>Select Category</h4>
          </div>
          <ul class="category-by-cat">
            <li>
              <!-- <router-link :to="categories.length >= 1 ? '/categories/' + categories[0].id : '/'" class="single-cat-item"> -->
              <a :href="'/categories/' + categories[0].id" class="single-cat-item">
                <div>
                  <div class="icon">
                    <img :src="categories[0].imagepath ? `${medialURL}${categories[0].imagepath}`  : '/images/category/icon-7.svg'" alt="">
                  </div>
                  <div class="text"> {{ categories.length >=1 ? categories[0].name : '' }} </div>
                </div>
              </a>
              <!-- </router-link> -->
            </li>
            <!-- <li>
              <a :href="'/categories/' + categories[1].id" class="single-cat-item">
                <div class="icon">
                  <img :src="categories[1].imagepath ? `${medialURL}${categories[1].imagepath}`  : '/images/category/icon-2.svg'" alt="">
                </div>
                <div class="text"> {{ categories.length >= 2 ? categories[1].name : '' }} </div>
              </a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Category Model End-->
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      categories: 'category/getCategories',
      medialURL: 'settings/getMediaUrl',
    })
  },
  methods: {
    selectCategory() {
      
    }
  }
}
</script>