<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Offers</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="all-product-grid mb-14">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="default-title mt-4">
                <h2>Offers</h2>
                <img src="images/line.svg" alt="">
              </div>
            </div>
            <div class="col-lg-4"
              v-for="(offer, o) in offers"
              :key="`offer${o}`"
            >
              <router-link :to="offer.url"  class="offers-item">
                <div class="offer-img">
                  <img :src="offer.imagepath ? `${medialURL}${offer.imagepath}`  : 'images/banners/offer-5.jpg'" alt="">
                </div>
                <div class="offers-text">
                  <h4>{{ offer.desc_1 }}</h4>
                  <p>{{ offer.desc_2 }}</p>
                  <p>{{ offer.desc_3 }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>  
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      offers: 'offer/getOffers',
      medialURL: 'settings/getMediaUrl'
    })
  },
}
</script>