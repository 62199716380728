export default {
  namespaced: true,
  state: {
    order: {
      store: {
        delivery_charges: 0
      },
      user_id: '',
      details: '',
      order_cost: 0,
      total_cashback: 0,
      coupon_amount: 0,
      tax_percent: 0,
      tax_amount: 0,
      packaging_amount: 0,
      delivery_amount: 0,
      tip_amount: 0,
      total_amount: 0,
      wallet_amount_used: 0,
      order_details: []
    }
  },
  getters: {
    getOrder(state) {
      return state.order;
    }
  },
  mutations: {
    SET_ORDER(state, order) {
      state.order = order
      localStorage.setItem('order', JSON.stringify(state.order))
    },
    ADD_ORDER_DETAIL(state, orderDetail) {
      if (state.order.store.id == null) {
        state.order.store = orderDetail.store
        state.order.delivery_amount = orderDetail.store.delivery_charges || 0
        state.order.packaging_amount = orderDetail.store.packaging_charges
        state.order.tax_percent = orderDetail.store.tax_percent
      }
      let prodDetailAlreadyThere = state.order.order_details.find(d => d.product_id == orderDetail.product_id && d.product_pack == orderDetail.product_pack)
      if (prodDetailAlreadyThere) {
        // prodDetailAlreadyThere = orderDetail
        prodDetailAlreadyThere.product_id = orderDetail.product_id
        prodDetailAlreadyThere.quantity = orderDetail.quantity
        prodDetailAlreadyThere.order_detail_cost = orderDetail.order_detail_cost
        prodDetailAlreadyThere.product_pack = orderDetail.product_pack
        prodDetailAlreadyThere.product = orderDetail.product
        prodDetailAlreadyThere.cashback = orderDetail.cashback
      }
      else {
        state.order.order_details.push(orderDetail)
      }
    },
    REMOVE_ORDER_DETAIL(state, orderDetail) {
      let order_details = state.order.order_details.filter(d => d != orderDetail)
      state.order.order_details = order_details
      if (state.order.order_details.length == 0) {
        state.order.store = {
          delivery_charges: 0
        }
        state.order.delivery_amount = 0
        state.order.order_cost = 0
        state.order.total_cashback = 0
        state.order.coupon_amount = 0
        state.order.tax_percent = 0
        state.order.tax_amount = 0
        state.order.packaging_amount = 0
        state.order.delivery_amount = 0
        state.order.tip_amount = 0
        state.order.total_amount = 0
        state.order.wallet_amount_used = 0
      }

    },
    UPDATE_ORDER_COST(state) {
      let cost = 0
      state.order.order_details.forEach(detail => {
        cost += parseFloat(detail.order_detail_cost)
      })
      state.order.order_cost = cost

      let cashback = 0
      state.order.order_details.forEach(detail => {
        cashback += parseFloat(detail.cashback)
      })
      state.order.total_cashback = cashback
      state.order.tax_amount = state.order.order_cost * state.order.tax_percent / 100
    },
    EMPTY_ORDER(state) {
      state.order = {
        'store': {
          delivery_charges: 0
        },
        'user_id': '',
        'details': '',
        'order_cost': 0,
        'total_cashback': 0,
        'coupon_amount': 0,
        'tax_percent': 0,
        'tax_amount': 0,
        'packaging_amount': 0,
        'delivery_amount': 0,
        'tip_amount': 0,
        'total_amount': 0,
        'wallet_amount_used': 0,
        'order_details': [],
      }
      localStorage.setItem('order', JSON.stringify(state.order))
    },
  },
  actions: {
    setOrder({ commit }, order) {
      commit('SET_ORDER', order)
    },
    addOrderDetail({ state, commit }, orderDetail) {
      commit('ADD_ORDER_DETAIL', orderDetail)
      commit('UPDATE_ORDER_COST')
      commit('SET_ORDER', state.order)
    },
    removeOrderDetail({ state, commit }, orderDetail) {
      commit('REMOVE_ORDER_DETAIL', orderDetail)
      commit('UPDATE_ORDER_COST')
      commit('SET_ORDER', state.order)
    },
    fetchOrder({ dispatch }) {
      let fetchedOrder = localStorage.getItem('order') ? JSON.parse(localStorage.getItem('order')) : {
        'store': {
          delivery_charges: 0
        },
        'user_id': '',
        'details': '',
        'order_cost': 0,
        'total_cashback': 0,
        'coupon_amount': 0,
        'tax_percent': 0,
        'tax_amount': 0,
        'packaging_amount': 0,
        'delivery_amount': 0,
        'tip_amount': 0,
        'total_amount': 0,
        'wallet_amount_used': 0,
        'order_details': [],
      }
      dispatch('setOrder', fetchedOrder)
    },
    emptyOrder({ commit }) {
      commit('EMPTY_ORDER');
    },
  }
}