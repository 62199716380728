<template>
  <div class="col-lg-9 col-md-8">
    <div class="dashboard-right">
      <div class="row">
        <div class="col-md-12">
          <div class="main-title-tab">
            <h4><i class="uil uil-location-point"></i>My Address</h4>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="pdpt-bg">
            <div class="pdpt-title">
              <h4>My Address</h4>
            </div>
            <div class="address-body">
              <form @submit.prevent="updateUser">
                <div class="address-item">
                  <div class="address-icon1">
                    <i class="uil uil-home-alt"></i>
                  </div>
                    <div class="address-dt-all">
                      <h4>Home</h4>
                      <p>{{ user.add_1 || ''}} {{ user.add_2 || ''}} {{ user.add_3 || ''}} {{ user.city || ''}} {{ user.pincode || ''}}</p>

                      <ul class="action-btns">
                        <li>
                          <div class="form-group pos_rel">
                            <input id="phone[number]" name="phone" type="text" placeholder="Flat No / Building Name" class="form-control lgn_input" required="" v-model="form.add_1">
                            <i class="uil uil-mobile-android-alt lgn_icon"></i>
                          </div>
                        </li>
                        <li>
                          <div class="form-group pos_rel">
                            <input id="phone[number]" name="phone" type="text" placeholder="Street / Sector" class="form-control lgn_input" required="" v-model="form.add_2">
                            <i class="uil uil-mobile-android-alt lgn_icon"></i>
                          </div>
                        </li>
                        <li>
                          <div class="form-group pos_rel">
                            <input id="phone[number]" name="phone" type="text" placeholder="Landmark" class="form-control lgn_input" required="" v-model="form.add_3">
                            <i class="uil uil-mobile-android-alt lgn_icon"></i>
                          </div>
                        </li>
                        <li>
                          <div class="form-group pos_rel">
                            <select class="form-control lgn_input" v-model="form.city_id" required>
                              <option value="">Select City</option>
                              <option
                                v-for="(city, c) in cities"
                                :key="`city${c}`"
                                :value="city.id"
                              >
                                {{ city.name }}
                              </option>
                            </select>
                            <!-- <input id="phone[number]" name="phone" type="text" placeholder="City" class="form-control lgn_input" required="" v-model="form.city"> -->
                          </div>
                        </li>
                        <li>
                          <div class="form-group pos_rel">
                            <input id="phone[number]" name="phone" type="text" placeholder="Pincode" class="form-control lgn_input" required="" v-model="form.pincode">
                            <i class="uil uil-mobile-android-alt lgn_icon"></i>
                          </div>
                        </li>
                      </ul>
                    </div>
                </div>
                <button @submit="updateUser" class="add-address hover-btn" data-toggle="modal" data-target="#address_model">Update Address</button>&nbsp;
                <span
                  v-if="done"
                >Updated</span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  data () {
    return {
      form: {
        add_1: '',
        add_2: '',
        add_3: '',
        city: '',
        pincode: '',
      },
      done: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      cities: 'city/getCities'
    })
  },
  mounted() {
    this.form = this.user
    this.form.city = this.form.city || ''
  },
  methods: {
    async updateUser() {
      this.done = false
      await axios.patch(`/users/${this.user.id}`, this.form)
      this.done = true
    }
  }
}
</script>