  
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


// declare a request interceptor
axios.interceptors.request.use(
  (config) => {
    store.dispatch('errors/setErrors', {});
    store.dispatch('errors/setStatus', {});

    // For headers
    let loggedInUser = Object.assign(store.getters['auth/user'] || {});
    if (loggedInUser.id) {
      let loggedInUserSites = loggedInUser ? loggedInUser.companies : '';
      let loggedInUserSitesId = loggedInUserSites.length
        ? loggedInUserSites[0].id
        : '';
      let loggedInUserApiToken = loggedInUser.api_token;
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + loggedInUserApiToken;
      axios.defaults.headers.common['company-id'] = loggedInUserSitesId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// declare a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errors = error.response ? error.response.data.errors : {};
    Object.keys(errors).forEach((key) => {
      errors[key] = errors[key][0];
    });
    store.dispatch('errors/setErrors', errors);
    store.dispatch(
      'errors/setStatus',
      error.response ? error.response.status : ''
    );
    return Promise.reject(error);
  }
);

import AsyncComputed from 'vue-async-computed';
Vue.use(AsyncComputed);

require('@/store/subscriber');
require('@/filters.js');

Vue.config.productionTip = false;

axios.defaults.baseURL = "https://api.bijleebox.com/api";
store.dispatch(
  'settings/setMediaUrl',
  "https://az-do-bucket-1.blr1.cdn.digitaloceanspaces.com/"
);

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  store.dispatch('city/fetchCity').then(() => {
    store.dispatch('category/fetchCategories').then(() => {
      store.dispatch('offer/fetchOffers').then(() => {
        new Vue({
          router,
          store,
          render: (h) => h(App),
        }).$mount('#app');
      });
    });
  });
});

