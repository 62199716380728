export default {
  namespaced: true,
  state: {
    mediaURL: null
  },
  getters: {
    getMediaUrl(state) {
      return state.mediaURL
    }
  },
  mutations: {
    SET_MEDIA_URL(state, data) {
      state.mediaURL = data
    }
  },
  actions: {
    setMediaUrl({ commit }, url) {
      commit('SET_MEDIA_URL', url)
    }
  }
}