<template>
  <!-- Cart Sidebar Offset Start-->
  <div class="bs-canvas bs-canvas-left position-fixed bg-cart h-100">
    <div class="bs-canvas-header side-cart-header p-3">
      <div class="d-inline-block main-cart-title">
        My Cart <span>({{ order.order_details.length }} Items)</span>
      </div>
      <button type="button" class="bs-canvas-close close" aria-label="Close">
        <i class="uil uil-multiply"></i>
      </button>
    </div>
    <div class="bs-canvas-body">
      <div class="cart-top-total">
        <div class="cart-total-dil">
          <h4>{{ order.store.name }}</h4>
          <span>₹ {{ order.order_cost | twoDecimal }}</span>
        </div>
        <div class="cart-total-dil pt-2">
          <h4>Delivery Charges</h4>
          <span>₹ {{ order.delivery_amount | twoDecimal }}</span>
        </div>
      </div>
      <div class="side-cart-items">
        <div class="cart-item" v-for="(detail, d) in order.order_details" :key="`detail${d}`">
          <div class="cart-product-img">
            <img :src="detail.product.imagepath1
                ? `${mediaURL}${detail.product.imagepath1}`
                : '/images/product/img-1.jpg'
              " alt="" />
            <div class="offer-badge" v-if="detail.product.product_prices[0].cashback_price">
              ₹ {{ detail.cashback | twoDecimal }} CB
            </div>
            <div class="brand-image-badge">
              <img :src="detail.product.sub_2_category.imagepath
                  ? `${mediaURL}${detail.product.sub_2_category.imagepath}`
                  : '/images/product/img-1.jpg'
                " style="width: 50px" alt="" />
            </div>
          </div>
          <div class="cart-text">
            <h4>{{ detail.product.name }}</h4>
            <h4 class="mb-0">
              {{ detail.product.sub_3_category ? detail.product.sub_3_category.name : "" }}
              {{ detail.product.catelog_no || "" }}
            </h4>
            <div class="cart-radio" v-if="detail.product_pack != null">
              <ul class="kggrm-now">
                <li>
                  <input type="radio" id="a1" name="cart1" />
                  <label for="a1">{{ detail.product_pack }}</label>
                </li>
              </ul>
            </div>
            <div class="qty-group">
              <div class="quantity buttons_added">
                <input type="button" value="-" class="minus minus-btn" @click="decreementProduct(detail)" />
                <input type="number" step="1" :value="detail.quantity" class="input-text qty text" />
                <input type="button" value="+" class="plus plus-btn" @click="increementProduct(detail)" />
              </div>
              <div class="cart-item-price">
                ₹ {{ detail.order_detail_cost | twoDecimal }}
                <br />
                {{
                  detail.product.product_prices[0].cashback_price
                    ? "₹ " + detail.cashback + " Cashback"
                    : ""
                }}
              </div>
              <br />
            </div>
            <b v-if="detail.product.product_prices.offer_percent">You save {{
              detail.product.product_prices.offer_percent }} %</b>

            <button type="button" class="cart-close-btn" @click="removeOrderDetailFrontEnd(detail)">
              <i class="uil uil-multiply"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bs-canvas-footer">
      <div class="cart-total-dil saving-total">
        <h4>Total Cashback</h4>
        <span>₹ {{ order.total_cashback | twoDecimal }}</span>
      </div>
      <div class="cart-total-dil saving-total">
        <h4>Sub Total</h4>
        <span>₹ {{ order.order_cost | twoDecimal }}</span>
      </div>
      <div class="cart-total-dil saving-total">
        <h4>Tax</h4>
        <span>₹ {{ order.tax_amount | twoDecimal }}</span>
      </div>
      <div class="cart-total-dil saving-total">
        <h4>Packaging</h4>
        <span>₹ {{ order.packaging_amount | twoDecimal }}</span>
      </div>
      <div class="cart-total-dil saving-total">
        <h4>Delivery</h4>
        <span>₹ {{ order.delivery_amount | twoDecimal }}</span>
      </div>
      <div class="main-total-cart">
        <h2>Total</h2>
        <span>₹
          {{
            (
              parseFloat(order.order_cost) +
              parseFloat(order.tax_amount) +
              parseFloat(order.packaging_amount) +
              parseFloat(order.delivery_amount)
            ).toFixed(2) | twoDecimal
          }}</span>
      </div>
      <div class="checkout-cart" v-if="order.order_details.length > 0">
        <!-- <a href="#" class="promo-code">Have a promocode?</a> -->
        <a class="cart-checkout-btn hover-btn" href="/checkout">
          Proceed to Checkout
        </a>
      </div>
    </div>
  </div>
  <!-- Cart Sidebar Offsetl End-->
</template>

<script type="text/javascript">
import { mapGetters } from "vuex";
import { addOrderDetail } from "@/helpers/index.js";

export default {
  computed: {
    ...mapGetters({
      order: "order/getOrder",
      mediaURL: "settings/getMediaUrl",
    }),
  },
  mounted() {
    console.log("order details full", this.order);
  },
  methods: {
    removeOrderDetailFrontEnd(orderDetail) {
      console.log("ordeer remnove", orderDetail);
      this.$store.dispatch("order/removeOrderDetail", orderDetail);
    },
    decreementProduct(orderDetail) {
      orderDetail.product.product_price.selectedQuantity -= 1;
      if (orderDetail.product.product_price.selectedQuantity >= 1) {
        this.addOrderDetailFrontEnd(orderDetail.product);
      } else {
        this.removeOrderDetailFrontEnd(orderDetail);
      }
    },
    increementProduct(orderDetail) {
      console.log("before", orderDetail.product.product_price.selectedQuantity);
      orderDetail.product.product_price.selectedQuantity =
        orderDetail.product.product_price.selectedQuantity + 1;
      console.log("after", orderDetail.product.product_price.selectedQuantity);
      this.addOrderDetailFrontEnd(orderDetail.product);
    },
    addOrderDetailFrontEnd(product) {
      console.log(this.store, product);

      let orderDetail = addOrderDetail(this.store, product);
      this.$store.dispatch("order/addOrderDetail", orderDetail);
    },
  },
};
</script>
<style scoped>
.brand-image-badge {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  padding: 0px;
  border-radius: 3px;
}
</style>
