<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <!-- Offers Start -->
      <div class="main-banner-slider">
        <div class="container-2">
          <div>
            <div class="col-md-12 px-0">
              <hooper :infiniteScroll="true" :autoPlay="true" :playSpeed="2000" v-if="banners.length != 0"
                :wheelControl="false">
                <slide v-for="(bannerPath, index) in banners" :key="index">
                  <b-skeleton-img v-if="!isBannerImageLoaded[index]" height="500px"></b-skeleton-img>
                  <img class="banner-image" @load="
                    loadBannerImage(
                      index,
                      `https://ik.imagekit.io/edefqapdy/${bannerPath}`
                    )
                    " :src="isBannerImageLoaded[index]
                      ? compressedBannerImageUrls[index]
                      : '/images/product/img-1.jpg'
                      " v-show="isBannerImageLoaded[index]" alt="" style="width: 100%" />
                </slide>
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
              <!-- <b-carousel-slide
                  :img-src="`${mediaURL}${setting.banner_path_11}`"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="`${mediaURL}${setting.banner_path_12}`"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="`${mediaURL}${setting.banner_path_13}`"
                ></b-carousel-slide> -->
              <!-- <b-carousel-slide :img-src="`${mediaURL}${setting.banner_path_6}`"></b-carousel-slide> -->
            </div>
            <!-- Categories Start -->
            <div class="section145">
              <div class="container" style="background: white; padding-bottom: 21px">
                <div class="row">
                  <div class="col-md-12">
                    <div class="main-title-tt">
                      <div class="main-title-left">
                        <span>Shop By</span>
                        <h2>Categories</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 px-0">
                    <b-row class="shadow-sm my-2 categories-class"
                      style="border: 1px solid #0000002b; border-radius: 9px;">
                      <b-col cols="3" xl="2" lg="2" class="item mb-3 p-0" v-for="(subcategory, i) in categories[0]
                        .sub_categories_filter" :key="i">
                        <router-link :to="`/categories/1/stores/1?sub_category_id=${subcategory.id}`"
                          class="category-item">
                          <div class="cate-img">
                            <img style="width: 50px" :src="`${mediaURL}${subcategory.imagepath}`" alt="" />
                          </div>
                          <h4>{{ subcategory.name }}</h4>
                        </router-link>
                      </b-col>
                    </b-row>
                    <b-row>
                      <div class="item col-md-12 shadow-sm mb-3"
                        style="border: 1px solid #0000002b; border-radius: 9px">
                        <router-link to="/product-customizations" class="category-item">
                          <div class="cate-img">
                            <img style="width: 50px" src="../assets/customize.png" alt="" />
                          </div>
                          <h4>Customize Your House</h4>
                        </router-link>
                      </div>
                      <div class="col-md-12">
                        <div class="main-title-tt mt-3">
                          <div class="main-title-left">
                            <h2>Switches</h2>
                          </div>
                        </div>
                      </div>
                      <div class="container" :class="isWideScreen ? 'p-4' : 'p-1'"
                        style="background: #efeff4; border-radius: 12px" v-if="categories.length != 0">
                        <div class="col-md-12 p-0" style="background: white; border-radius: 12px">
                          <b-row class="row home-switch p-3">
                            <b-col cols="12" class="pt-2 mb-2 font-weight-bold" style="font-size: 19px">
                              Switches & Related Categories
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="3" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[0].id}&sub_2_category_id=${categories[0].sub_categories_filter[0].sub_2_categories[0].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[0].sub_2_categories[0].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="9" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[0].id}&sub_2_category_id=${categories[0].sub_categories_filter[0].sub_2_categories[1].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[0].sub_2_categories[1].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[0].products[0].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[0].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[0]
                                  .sub_2_categories[0].sub_3_categories[1].name)} ${encodeURI(categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[1].products[0].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[1].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[1]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[1]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[0]
                                  .sub_2_categories[0].sub_3_categories[2].name)} ${encodeURI(categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[2].products[0].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[2].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[2]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[2]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[0]
                                  .sub_2_categories[0].sub_3_categories[3].name)} ${encodeURI(categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[3].products[0].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[0].sub_2_categories[0].sub_3_categories[3].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[3]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[0]
                                          .sub_2_categories[0].sub_3_categories[3]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                          </b-row>
                          <b-col cols="12" class="px-0 py-0" style="background: #efeff4; height: 60px">
                          </b-col>
                          <b-col cols="12" class="px-0 pt-2" style="background: #efeff4">
                            <div v-if="Switchesproducts.length > 0">
                              <hooper :itemsToShow="itemsToShow" :itemsToSlide="1" :infiniteScroll="true"
                                :wheelControl="false">
                                <slide v-for="(  product, s  ) in Switchesproducts  " :key="`product${s}`">
                                  <div>
                                    <div style="
                                        position: fixed;
                                        bottom: 20px;
                                        right: 20px;
                                        z-index: 1000;
                                      " v-if="toasts[product.id]">
                                      <div class="alert alert-success" role="alert">
                                        <img style="width: 50px; height: 50px" :src="product.imagepath1
                                          ? `${mediaURL}${product.imagepath1}`
                                          : '/images/product/img-1.jpg'
                                          " />
                                        <br />
                                        {{ product.product_price.pack_value }}
                                        {{ product.product_price.unit }} of
                                        {{ product.name }}
                                        <br />
                                        <strong> Added To Cart</strong>
                                      </div>
                                    </div>
                                    <div class="product-item px-3" style="height: 377px">
                                      <router-link
                                        :to="`/categories/${categories[0].id}/stores/${categories[0].companies[0].id}/products/${product.id}`"
                                        class="product-img">
                                        <b-skeleton-img v-if="!isImageLoaded[product.id]"
                                          height="200px"></b-skeleton-img>
                                        <img @load="
                                          loadImage(
                                            product.id,
                                            `${'https://ik.imagekit.io/edefqapdy/'}${product.imagepath1
                                            }`
                                          )
                                          " :src="isImageLoaded[product.id]
                                            ? compressedImageUrls[product.id]
                                            : '/images/product/img-1.jpg'
                                            " v-show="isImageLoaded[product.id]" alt="" style="height: 200px" />
                                        <div class="product-absolute-options">
                                          <span class="offer-badge-1 p-1" v-if="product.product_price
                                            .offer_percent != 0 ||
                                            product.product_price
                                              .cashback_price
                                          ">
                                            <div align="start">
                                              <img :src="product.sub_2_category
                                                .imagepath
                                                ? `${mediaURL}${product.sub_2_category.imagepath}`
                                                : '/images/product/img-1.jpg'
                                                " style="width: 85px" alt="" />
                                            </div>
                                          </span>
                                        </div>
                                      </router-link>
                                      <div class="product-text-dt">
                                        <h4>
                                          {{
                                            product.name +
                                            " - " +
                                            (product.sub_3_category
                                              ? product.sub_3_category.name
                                              : "")
                                          }}
                                        </h4>
                                        <div class="product-price" v-if="product.product_price.offer_price !=
                                          product.product_price.price
                                        ">
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                            | twoDecimal
                                          }}
                                          <br />
                                          <span class="real-price">₹
                                            {{
                                              product.product_price.price
                                              | twoDecimal
                                            }}
                                          </span>
                                          <span class="off-percent" v-if="product.product_price
                                            .offer_percent != 0
                                          ">
                                            {{
                                              product.product_price
                                                .offer_percent || 0
                                            }}% OFF
                                          </span>
                                        </div>
                                        <div class="product-price" v-else>
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                          }}
                                        </div>
                                        <div class="qty-cart">
                                          <div style="padding-right: 10px">
                                            <select class="form-control" @change="
                                              updateProductPriceByDropDownFrontEnd(
                                                product,
                                                product.selectedProductPriceId
                                              )
                                              " v-model="product.selectedProductPriceId
                                                ">
                                              <option v-for="(
                                                    price, p  
                                                ) in product.product_prices  " :key="`price${p}`" :value="price.id">
                                                {{ price.product_pack.value }}
                                                {{
                                                  price.product_pack.unit.name
                                                }}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="quantity buttons_added" v-if="order.order_details.find(
                                            (d) =>
                                              d.product_id == product.id
                                          ) == null
                                          ">
                                            <span class="plus plus-btn add-to-card-btn" @click="
                                              increementProduct(0, product)
                                              ">Add To Cart</span>
                                          </div>
                                          <div class="quantity buttons_added" v-else>
                                            <input type="button" value="-" class="minus minus-btn" @click="
                                              decreementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id ==
                                                    product.id
                                                ),
                                                product
                                              )
                                              " />
                                            <input type="number" step="1" :value="order.order_details.find(
                                              (d) =>
                                                d.product_id == product.id
                                            ).quantity
                                              " class="input-text qty text" />
                                            <input type="button" value="+" class="plus plus-btn" @click="
                                              increementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id == product.id
                                                ).quantity,
                                                product
                                              )
                                              " />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                              </hooper>
                            </div>
                            <div v-else class="loader-class">
                              <img style="width: 100px" src="../assets/loader.gif" alt="" />
                            </div>
                          </b-col>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="main-title-tt mt-3">
                          <div class="main-title-left">
                            <h2>Wires</h2>
                          </div>
                        </div>
                      </div>
                      <div class="container" :class="isWideScreen ? 'p-4' : 'p-1'"
                        style="background: #efeff4; border-radius: 12px" v-if="categories.length != 0">
                        <div class="col-md-12 p-0" style="background: white; border-radius: 12px">
                          <b-row class="row home-switch p-3">
                            <b-col cols="12" class="pt-2 mb-2 font-weight-bold" style="font-size: 19px">
                              Wires & Related Categories
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="3" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[1].id}&sub_2_category_id=${categories[0].sub_categories_filter[1].sub_2_categories[0].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[1].sub_2_categories[0].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="9" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[1].id}&sub_2_category_id=${categories[0].sub_categories_filter[1].sub_2_categories[1].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[1].sub_2_categories[1].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword= ${encodeURI(categories[0].sub_categories_filter[1]
                                  .sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[1].sub_2_categories[0].sub_3_categories[0].products[0].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[1].sub_2_categories[0].sub_3_categories[0].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold text-center">
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[1]
                                  .sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[1].sub_2_categories[0].sub_3_categories[0].products[1].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[1].sub_2_categories[0].sub_3_categories[0].products[1].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold text-center">
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[0].sub_3_categories[1]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword= ${encodeURI(categories[0].sub_categories_filter[1]
                                  .sub_2_categories[1].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[1].sub_2_categories[1].sub_3_categories[0].products[0].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[1].sub_2_categories[1].sub_3_categories[0].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold text-center">
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[1].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[1].sub_3_categories[0]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword= ${encodeURI(categories[0].sub_categories_filter[1]
                                  .sub_2_categories[1].sub_3_categories[1].name)} ${encodeURI(categories[0].sub_categories_filter[1].sub_2_categories[1].sub_3_categories[0].products[1].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[1].sub_2_categories[1].sub_3_categories[1].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold text-center">
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[1].sub_3_categories[1]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[1]
                                          .sub_2_categories[1].sub_3_categories[1]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                          </b-row>
                          <b-col cols="12" class="px-0 py-0" style="background: #efeff4; height: 60px">
                          </b-col>
                          <b-col cols="12" class="px-0 pt-2" style="background: #efeff4">
                            <div v-if="Wiresproducts.length > 0">
                              <hooper :itemsToShow="itemsToShow" :itemsToSlide="1" :infiniteScroll="true"
                                :wheelControl="false">
                                <slide v-for="(  product, s  ) in Wiresproducts  " :key="`product${s}`">
                                  <div>
                                    <div style="
                                        position: fixed;
                                        bottom: 20px;
                                        right: 20px;
                                        z-index: 1000;
                                      " v-if="toasts[product.id]">
                                      <div class="alert alert-success" role="alert">
                                        <img style="width: 50px; height: 50px" :src="product.imagepath1
                                          ? `${mediaURL}${product.imagepath1}`
                                          : '/images/product/img-1.jpg'
                                          " />
                                        <br />
                                        {{ product.product_price.pack_value }}
                                        {{ product.product_price.unit }} of
                                        {{ product.name }}
                                        <br />
                                        <strong> Added To Cart</strong>
                                      </div>
                                    </div>
                                    <div class="product-item px-3" style="height: 377px">
                                      <router-link
                                        :to="`/categories/${categories[0].id}/stores/${categories[0].companies[0].id}/products/${product.id}`"
                                        class="product-img">
                                        <b-skeleton-img v-if="!isImageLoaded[product.id]"
                                          height="200px"></b-skeleton-img>
                                        <img @load="
                                          loadImage(
                                            product.id,
                                            `${'https://ik.imagekit.io/edefqapdy/'}${product.imagepath1
                                            }`
                                          )
                                          " :src="isImageLoaded[product.id]
                                            ? compressedImageUrls[product.id]
                                            : '/images/product/img-1.jpg'
                                            " v-show="isImageLoaded[product.id]" alt="" style="height: 200px" />
                                        <div class="product-absolute-options">
                                          <span class="offer-badge-1 p-1" v-if="product.product_price
                                            .offer_percent != 0 ||
                                            product.product_price
                                              .cashback_price
                                          ">
                                            <div align="start">
                                              <img :src="product.sub_2_category
                                                .imagepath
                                                ? `${mediaURL}${product.sub_2_category.imagepath}`
                                                : '/images/product/img-1.jpg'
                                                " style="width: 85px" alt="" />
                                            </div>
                                          </span>
                                        </div>
                                      </router-link>
                                      <div class="product-text-dt">
                                        <h4>
                                          {{
                                            product.name +
                                            " - " +
                                            (product.sub_3_category
                                              ? product.sub_3_category.name
                                              : "")
                                          }}
                                        </h4>
                                        <div class="product-price" v-if="product.product_price.offer_price !=
                                          product.product_price.price
                                        ">
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                            | twoDecimal
                                          }}
                                          <br />
                                          <span class="real-price">₹
                                            {{
                                              product.product_price.price
                                              | twoDecimal
                                            }}
                                          </span>
                                          <span class="off-percent" v-if="product.product_price
                                            .offer_percent != 0
                                          ">
                                            {{
                                              product.product_price
                                                .offer_percent || 0
                                            }}% OFF
                                          </span>
                                        </div>
                                        <div class="product-price" v-else>
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                          }}
                                        </div>
                                        <div class="qty-cart">
                                          <div style="padding-right: 10px">
                                            <select class="form-control" @change="
                                              updateProductPriceByDropDownFrontEnd(
                                                product,
                                                product.selectedProductPriceId
                                              )
                                              " v-model="product.selectedProductPriceId
                                                ">
                                              <option v-for="(
                                                    price, p  
                                                ) in product.product_prices  " :key="`price${p}`" :value="price.id">
                                                {{ price.product_pack.value }}
                                                {{
                                                  price.product_pack.unit.name
                                                }}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="quantity buttons_added" v-if="order.order_details.find(
                                            (d) =>
                                              d.product_id == product.id
                                          ) == null
                                          ">
                                            <span class="plus plus-btn add-to-card-btn" @click="
                                              increementProduct(0, product)
                                              ">Add To Cart</span>
                                          </div>
                                          <div class="quantity buttons_added" v-else>
                                            <input type="button" value="-" class="minus minus-btn" @click="
                                              decreementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id ==
                                                    product.id &&
                                                    d.product_pack ==
                                                    product.product_price
                                                      .pack_value +
                                                    ' ' +
                                                    product.product_price
                                                      .unit
                                                ),
                                                product
                                              )
                                              " />
                                            <input type="number" step="1" :value="order.order_details.find(
                                              (d) =>
                                                d.product_id == product.id
                                            ).quantity
                                              " class="input-text qty text" />
                                            <input type="button" value="+" class="plus plus-btn" @click="
                                              increementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id == product.id
                                                ).quantity,
                                                product
                                              )
                                              " />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                              </hooper>
                            </div>
                            <div v-else class="loader-class">
                              <img style="width: 100px" src="../assets/loader.gif" alt="" />
                            </div>
                          </b-col>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="main-title-tt mt-3">
                          <div class="main-title-left">
                            <h2>MCBS & RCCB</h2>
                          </div>
                        </div>
                      </div>
                      <div class="container" :class="isWideScreen ? 'p-4' : 'p-1'"
                        style="background: #efeff4; border-radius: 12px" v-if="categories.length != 0">
                        <div class="col-md-12 p-0" style="background: white; border-radius: 12px">
                          <b-row class="row home-switch p-3">
                            <b-col cols="12" class="pt-2 mb-2 font-weight-bold" style="font-size: 19px">
                              MCBS & RCCB & Related Categories
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="3" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[3].id}&sub_2_category_id=${categories[0].sub_categories_filter[3].sub_2_categories[0].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[3].sub_2_categories[0].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="9" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[3].id}&sub_2_category_id=${categories[0].sub_categories_filter[3].sub_2_categories[1].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[3].sub_2_categories[1].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[3].sub_2_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[3].sub_2_categories[0].sub_3_categories[0].products[0].name)}`">
                                <b-card v-if="categories[0].sub_categories_filter[3]
                                  .sub_2_categories[0].sub_3_categories[0]
                                  .products.length != 0
                                " :img-src="`${mediaURL}${categories[0].sub_categories_filter[3].sub_2_categories[0].sub_3_categories[0].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[0].name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[3]
                                  .sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[3].sub_2_categories[0].sub_3_categories[0].products[1].name)}`">
                                <b-card v-if="categories[0].sub_categories_filter[3]
                                  .sub_2_categories[0].sub_3_categories[0]
                                  .products.length != 0
                                " :img-src="`${mediaURL}${categories[0].sub_categories_filter[3].sub_2_categories[0].sub_3_categories[0].products[1].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[1].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a v-if="categories[0].sub_categories_filter[3]
                                .sub_2_categories[1].sub_3_categories[1]
                                .products.length != 0
                              "
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[3]
                                  .sub_2_categories[1].sub_3_categories[1].name)} ${encodeURI(categories[0].sub_categories_filter[3].sub_2_categories[1].sub_3_categories[1].products[0].name)}`">
                                <b-card
                                  :img-src="` ${mediaURL}${categories[0].sub_categories_filter[3].sub_2_categories[1].sub_3_categories[1].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[1].sub_3_categories[1]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[1].sub_3_categories[1]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a v-if="categories[0].sub_categories_filter[3]
                                .sub_2_categories[1].sub_3_categories[1]
                                .products.length != 0
                              "
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[3]
                                  .sub_2_categories[1].sub_3_categories[1].name)} ${encodeURI(categories[0].sub_categories_filter[3].sub_2_categories[1].sub_3_categories[1].products[1].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[3].sub_2_categories[1].sub_3_categories[1].products[1].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[1].name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[1].sub_3_categories[1]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[3]
                                          .sub_2_categories[1].sub_3_categories[1]
                                          .products[1].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                          </b-row>
                          <b-col cols="12" class="px-0 py-0" style="background: #efeff4; height: 60px">
                          </b-col>
                          <b-col cols="12" class="px-0 pt-2" style="background: #efeff4">
                            <div v-if="McbsProducts.length > 0">
                              <hooper :itemsToShow="itemsToShow" :itemsToSlide="1" :infiniteScroll="true"
                                :wheelControl="false">
                                <slide v-for="(  product, s  ) in McbsProducts  " :key="`product${s}`">
                                  <div>
                                    <div style="
                                        position: fixed;
                                        bottom: 20px;
                                        right: 20px;
                                        z-index: 1000;
                                      " v-if="toasts[product.id]">
                                      <div class="alert alert-success" role="alert">
                                        <img style="width: 50px; height: 50px" :src="product.imagepath1
                                          ? `${mediaURL}${product.imagepath1}`
                                          : '/images/product/img-1.jpg'
                                          " />
                                        <br />
                                        {{ product.product_price.pack_value }}
                                        {{ product.product_price.unit }} of
                                        {{ product.name }}
                                        <br />
                                        <strong> Added To Cart</strong>
                                      </div>
                                    </div>
                                    <div class="product-item px-3" style="height: 377px">
                                      <router-link
                                        :to="`/categories/${categories[0].id}/stores/${categories[0].companies[0].id}/products/${product.id}`"
                                        class="product-img">
                                        <b-skeleton-img v-if="!isImageLoaded[product.id]"
                                          height="200px"></b-skeleton-img>
                                        <img @load="
                                          loadImage(
                                            product.id,
                                            `${'https://ik.imagekit.io/edefqapdy/'}${product.imagepath1
                                            }`
                                          )
                                          " :src="isImageLoaded[product.id]
                                            ? compressedImageUrls[product.id]
                                            : '/images/product/img-1.jpg'
                                            " v-show="isImageLoaded[product.id]" alt="" style="height: 200px" />
                                        <div class="product-absolute-options">
                                          <span class="offer-badge-1 p-1" v-if="product.product_price
                                            .offer_percent != 0 ||
                                            product.product_price
                                              .cashback_price
                                          ">
                                            <div align="start">
                                              <img :src="product.sub_2_category
                                                .imagepath
                                                ? `${mediaURL}${product.sub_2_category.imagepath}`
                                                : '/images/product/img-1.jpg'
                                                " style="width: 85px" alt="" />
                                            </div>
                                          </span>
                                        </div>
                                      </router-link>
                                      <div class="product-text-dt">
                                        <h4>
                                          {{
                                            product.name +
                                            " - " +
                                            (product.sub_3_category
                                              ? product.sub_3_category.name
                                              : "")
                                          }}
                                        </h4>
                                        <div class="product-price" v-if="product.product_price.offer_price !=
                                          product.product_price.price
                                        ">
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                            | twoDecimal
                                          }}
                                          <br />
                                          <span class="real-price">₹
                                            {{
                                              product.product_price.price
                                              | twoDecimal
                                            }}
                                          </span>
                                          <span class="off-percent" v-if="product.product_price
                                            .offer_percent != 0
                                          ">
                                            {{
                                              product.product_price
                                                .offer_percent || 0
                                            }}% OFF
                                          </span>
                                        </div>
                                        <div class="product-price" v-else>
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                          }}
                                        </div>
                                        <div class="qty-cart">
                                          <div style="padding-right: 10px">
                                            <select class="form-control" @change="
                                              updateProductPriceByDropDownFrontEnd(
                                                product,
                                                product.selectedProductPriceId
                                              )
                                              " v-model="product.selectedProductPriceId
                                                ">
                                              <option v-for="(
                                                    price, p  
                                                ) in product.product_prices  " :key="`price${p}`" :value="price.id">
                                                {{ price.product_pack.value }}
                                                {{
                                                  price.product_pack.unit.name
                                                }}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="quantity buttons_added" v-if="order.order_details.find(
                                            (d) =>
                                              d.product_id == product.id
                                          ) == null
                                          ">
                                            <span class="plus plus-btn add-to-card-btn" @click="
                                              increementProduct(0, product)
                                              ">Add To Cart</span>
                                          </div>
                                          <div class="quantity buttons_added" v-else>
                                            <input type="button" value="-" class="minus minus-btn" @click="
                                              decreementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id ==
                                                    product.id &&
                                                    d.product_pack ==
                                                    product.product_price
                                                      .pack_value +
                                                    ' ' +
                                                    product.product_price
                                                      .unit
                                                ),
                                                product
                                              )
                                              " />
                                            <input type="number" step="1" :value="order.order_details.find(
                                              (d) =>
                                                d.product_id == product.id
                                            ).quantity
                                              " class="input-text qty text" />
                                            <input type="button" value="+" class="plus plus-btn" @click="
                                              increementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id == product.id
                                                ).quantity,
                                                product
                                              )
                                              " />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                              </hooper>
                            </div>
                            <div v-else class="loader-class">
                              <img style="width: 100px" src="../assets/loader.gif" alt="" />
                            </div>
                          </b-col>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="main-title-tt mt-3">
                          <div class="main-title-left">
                            <h2>Conduits & Pipes</h2>
                          </div>
                        </div>
                      </div>
                      <div class="container" :class="isWideScreen ? 'p-4' : 'p-1'"
                        style="background: #efeff4; border-radius: 12px" v-if="categories.length != 0">
                        <div class="col-md-12 p-0" style="background: white; border-radius: 12px">
                          <b-row class="row home-switch p-3">
                            <b-col cols="12" class="pt-2 mb-2 font-weight-bold" style="font-size: 19px">
                              Conduits/Pipes & Related Categories
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="3" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[2].id}&sub_2_category_id=${categories[0].sub_categories_filter[2].sub_2_categories[0].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[2].sub_2_categories[0].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="1" lg="2" md="9" class="pl-2">
                              <a
                                :href="`categories/1/stores/1?sub_category_id=${categories[0].sub_categories_filter[2].id}&sub_2_category_id=${categories[0].sub_categories_filter[2].sub_2_categories[1].id}`">
                                <img class="round-image"
                                  :src="`${mediaURL}${categories[0].sub_categories_filter[2].sub_2_categories[1].imagepath}`" />
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a v-if="categories[0].sub_categories_filter[2]
                                .sub_2_categories[0].sub_3_categories[0]
                                .products.length != 0
                              "
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[2]
                                  .sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[0].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[0].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[0].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a v-if="categories[0].sub_categories_filter[2]
                                .sub_2_categories[0].sub_3_categories[0]
                                .products.length != 0
                              "
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[2]
                                  .sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[1].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[1].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[1].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a v-if="categories[0].sub_categories_filter[2]
                                .sub_2_categories[0].sub_3_categories[0]
                                .products.length != 0
                              "
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[2]
                                  .sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[2].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[2].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[2].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                            <b-col cols="6" xl="2" lg="3" md="4" class="brand-card p-3">
                              <a v-if="categories[0].sub_categories_filter[2]
                                .sub_2_categories[0].sub_3_categories[0]
                                .products.length != 0
                              "
                                :href="`/categories/1/stores/1?keyword=${encodeURI(categories[0].sub_categories_filter[2]
                                  .sub_2_categories[0].sub_3_categories[0].name)} ${encodeURI(categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[3].name)}`">
                                <b-card
                                  :img-src="`${mediaURL}${categories[0].sub_categories_filter[2].sub_2_categories[0].sub_3_categories[0].products[3].imagepath1}`"
                                  img-alt="Image" img-top tag="article" style="max-width: 30rem; background: #dbedff"
                                  class="mb-2 p-3">
                                  <b-card-text style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                  ">
                                    <b-col cols="12" class="font-weight-bold">
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .name
                                      }}
                                      {{
                                        categories[0].sub_categories_filter[2]
                                          .sub_2_categories[0].sub_3_categories[0]
                                          .products[3].name
                                      }}
                                    </b-col>
                                    <b-col cols="12" class="text-center">
                                      Explore More
                                    </b-col>
                                  </b-card-text>
                                </b-card>
                              </a>
                            </b-col>
                          </b-row>
                          <b-col cols="12" class="px-0 py-0" style="background: #efeff4; height: 60px">
                          </b-col>
                          <b-col cols="12" class="px-0 pt-2" style="background: #efeff4">
                            <div v-if="ConduitsAndPipesProducts.length > 0">
                              <hooper :itemsToShow="itemsToShow" :itemsToSlide="1" :infiniteScroll="true"
                                :wheelControl="false">
                                <slide v-for="(
                                      product, s  
                                  ) in ConduitsAndPipesProducts " :key="`product${s}`">
                                  <div>
                                    <div style="
                                        position: fixed;
                                        bottom: 20px;
                                        right: 20px;
                                        z-index: 1000;
                                      " v-if="toasts[product.id]">
                                      <div class="alert alert-success" role="alert">
                                        <img style="width: 50px; height: 50px" :src="product.imagepath1
                                          ? `${mediaURL}${product.imagepath1}`
                                          : '/images/product/img-1.jpg'
                                          " />
                                        <br />
                                        {{ product.product_price.pack_value }}
                                        {{ product.product_price.unit }} of
                                        {{ product.name }}
                                        <br />
                                        <strong> Added To Cart</strong>
                                      </div>
                                    </div>
                                    <div class="product-item px-3" style="height: 377px">
                                      <router-link
                                        :to="`/categories/${categories[0].id}/stores/${categories[0].companies[0].id}/products/${product.id}`"
                                        class="product-img">
                                        <b-skeleton-img v-if="!isImageLoaded[product.id]"
                                          height="200px"></b-skeleton-img>
                                        <img @load="
                                          loadImage(
                                            product.id,
                                            `${'https://ik.imagekit.io/edefqapdy/'}${product.imagepath1
                                            }`
                                          )
                                          " :src="isImageLoaded[product.id]
                                            ? compressedImageUrls[product.id]
                                            : '/images/product/img-1.jpg'
                                            " v-show="isImageLoaded[product.id]" alt="" style="height: 200px" />
                                        <div class="product-absolute-options">
                                          <span class="offer-badge-1 p-1" v-if="product.product_price
                                            .offer_percent != 0 ||
                                            product.product_price
                                              .cashback_price
                                          ">
                                            <div align="start">
                                              <img :src="product.sub_2_category
                                                .imagepath
                                                ? `${mediaURL}${product.sub_2_category.imagepath}`
                                                : '/images/product/img-1.jpg'
                                                " style="width: 85px" alt="" />
                                            </div>
                                          </span>
                                        </div>
                                      </router-link>
                                      <div class="product-text-dt">
                                        <h4>
                                          {{
                                            product.name +
                                            " - " +
                                            (product.sub_3_category
                                              ? product.sub_3_category.name
                                              : "")
                                          }}
                                        </h4>
                                        <div class="product-price" v-if="product.product_price.offer_price !=
                                          product.product_price.price
                                        ">
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                            | twoDecimal
                                          }}
                                          <br />
                                          <span class="real-price">₹
                                            {{
                                              product.product_price.price
                                              | twoDecimal
                                            }}
                                          </span>
                                          <span class="off-percent" v-if="product.product_price
                                            .offer_percent != 0
                                          ">
                                            {{
                                              product.product_price
                                                .offer_percent || 0
                                            }}% OFF
                                          </span>
                                        </div>
                                        <div class="product-price" v-else>
                                          ₹
                                          {{
                                            product.product_price.offer_price
                                          }}
                                        </div>
                                        <div class="qty-cart">
                                          <div style="padding-right: 10px">
                                            <select class="form-control" @change="
                                              updateProductPriceByDropDownFrontEnd(
                                                product,
                                                product.selectedProductPriceId
                                              )
                                              " v-model="product.selectedProductPriceId
                                                ">
                                              <option v-for="(
                                                   price, p 
                                                ) in product.product_prices " :key="`price${p}`" :value="price.id">
                                                {{ price.product_pack.value }}
                                                {{
                                                  price.product_pack.unit.name
                                                }}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="quantity buttons_added" v-if="order.order_details.find(
                                            (d) =>
                                              d.product_id == product.id
                                          ) == null
                                          ">
                                            <span class="plus plus-btn add-to-card-btn" @click="
                                              increementProduct(0, product)
                                              ">Add To Cart</span>
                                          </div>
                                          <div class="quantity buttons_added" v-else>
                                            <input type="button" value="-" class="minus minus-btn" @click="
                                              decreementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id ==
                                                    product.id &&
                                                    d.product_pack ==
                                                    product.product_price
                                                      .pack_value +
                                                    ' ' +
                                                    product.product_price
                                                      .unit
                                                ),
                                                product
                                              )
                                              " />
                                            <input type="number" step="1" :value="order.order_details.find(
                                              (d) =>
                                                d.product_id == product.id
                                            ).quantity
                                              " class="input-text qty text" />
                                            <input type="button" value="+" class="plus plus-btn" @click="
                                              increementProduct(
                                                order.order_details.find(
                                                  (d) =>
                                                    d.product_id == product.id
                                                ).quantity,
                                                product
                                              )
                                              " />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                              </hooper>
                            </div>
                            <div v-else class="loader-class">
                              <img style="width: 100px" src="../assets/loader.gif" alt="" />
                            </div>
                          </b-col>
                        </div>
                      </div>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="main-title-tt">
                    <div class="main-title-left">
                      <span>Offers</span>
                      <h2>Best Values</h2>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <a href="#" class="best-offer-item">
                    <img :src="`${mediaURL}${setting.banner_path_1}`" alt="" />
                  </a>
                </div>
                <div class="col-lg-4 col-md-6">
                  <a href="#" class="best-offer-item">
                    <img :src="`${mediaURL}${setting.banner_path_2}`" alt="" />
                  </a>
                </div>
                <div class="col-lg-4 col-md-6">
                  <a href="#" class="best-offer-item offr-none">
                    <img :src="`${mediaURL}${setting.banner_path_3}`" alt="" />
                  </a>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex";
import axios from "axios";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import {
  selectDefaultProductPrice,
  updateProductPriceByDropDown,
  addOrderDetail,
} from "@/helpers/index.js";

export default {
  data: () => ({
    setting: [],
    Switchesproducts: [],
    Wiresproducts: [],
    ConduitsAndPipesProducts: [],
    McbsProducts: [],
    toasts: [],
    isWideScreen: window.innerWidth > 900,
    slide: 0,
    isImageLoaded: [],
    isBannerImageLoaded: [],
    compressedImageUrls: [],
    banners: [],
    compressedBannerImageUrls: [],
    sliding: null,
    LargScreen: false,
    imageQueue: [],
    isProcessingQueue: false,
    abortControllers: {},
  }),
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    // HooperPagination
  },
  computed: {
    ...mapGetters({
      categories: "category/getCategories",
      offers: "offer/getOffers",
      mediaURL: "settings/getMediaUrl",
      order: "order/getOrder",
    }),
    itemsToShow() {
      if (window.innerWidth >= 1600) {
        return 6;
      } else if (window.innerWidth < 720) {
        return 2;
      } else if (window.innerWidth < 1080) {
        return 4;
      } else {
        return 5;
      }
    },
  },
  mounted() {
    this.getSetting();
    this.getHomeBrandProduct();
  },
  beforeDestroy() {
    for (const productId in this.abortControllers) {
      if (this.abortControllers[productId]) {
        this.abortControllers[productId].abort(); // Abort the fetch request
      }
    }
    // Clear the image queue
    this.imageQueue = [];
    this.isProcessingQueue = false;
  },
  methods: {
    async getSetting() {
      let setting = await axios.get("/settings/1");
      this.setting = setting.data.data;
      const bannerKeys = ["banner_path_11", "banner_path_12", "banner_path_13"];
      bannerKeys.forEach((key) => {
        if (this.setting[key]) {
          this.banners.push(this.setting[key]);
        }
      });
    },
    getHomeBrandProduct() {
      this.categories[0].sub_categories_filter.forEach((element) => {
        this.getProducts(element);
      });
    },
    async getProducts(subcategory) {
      let products = subcategory.products;
      products = products.slice(0, 20);
      products = products.filter(
        (product) => product.is_active == 1 && product.product_prices.length > 0
      );
      // adding price to each product which is the default pricing
      let toasts = [];
      products.forEach((product) => {
        selectDefaultProductPrice(product, this.order);
        toasts[product.id] = false;
      });
      if (subcategory.name == "Switches") {
        this.Switchesproducts = products;
      }
      if (subcategory.name == "Wires") {
        this.Wiresproducts = products;
      }
      if (subcategory.name == "Conduits/Pipes") {
        this.ConduitsAndPipesProducts = products;
      }
      if (subcategory.name == "MCBS & RCCB") {
        this.McbsProducts = products;
      }
    },
    updateProductPriceByDropDownFrontEnd(product, priceId) {
      updateProductPriceByDropDown(product, priceId, this.order);
    },
    increementProduct(orderDetailQuantity, product) {
      if (
        this.order.store.id == null ||
        this.order.store.id == "" ||
        this.order.store.id == this.categories[0].companies[0].id
      ) {
        product.product_price.selectedQuantity = orderDetailQuantity + 1;
        this.addOrderDetailFrontEnd(product);
      } else {
        alert(
          "Order from another store has already been added. Please remove the order and try again"
        );
      }
    },
    decreementProduct(orderDetail, product) {
      let orderDetailQuantity = orderDetail.quantity;
      if (orderDetailQuantity > 1) {
        product.product_price.selectedQuantity = orderDetailQuantity - 1;
        this.addOrderDetailFrontEnd(product);
      } else if (orderDetailQuantity > 0) {
        this.$store.dispatch("order/removeOrderDetail", orderDetail);
      }
    },
    async loadImage(productId, imageUrl) {
      this.isLoading = true;
      const cleanedUrl = imageUrl.replace(/\/bb/, "");
      const encodedUrl = encodeURI(cleanedUrl);
      this.imageQueue.push({ productId, imageUrl: encodedUrl });
      if (!this.isProcessingQueue) {
        this.processImageQueue();
      }
      this.isLoading = false;
    },
    async loadBannerImage(settingId, BannerimageUrl) {
      this.isLoading = true;
      const cleanedUrl = BannerimageUrl.replace(/\/bb/, "");
      const encodedUrl = encodeURI(cleanedUrl);
      this.compressBannerImageSize(settingId, encodedUrl);
      this.isLoading = false;
    },
    async processImageQueue() {
      this.isProcessingQueue = true;

      while (this.imageQueue.length > 0) {
        const { productId, imageUrl } = this.imageQueue.shift();
        await this.getImageSize(productId, imageUrl);
      }

      this.isProcessingQueue = false;
    },
    async compressBannerImageSize(settingId, imageUrl, quality = 0.6) {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        await new Promise((resolve) => {
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Resize the canvas
            canvas.width = img.width * 0.5; // Adjust the scale as needed
            canvas.height = img.height * 0.5;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert canvas to blob
            canvas.toBlob(
              (compressedBlob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result;
                  this.$set(
                    this.compressedBannerImageUrls,
                    settingId,
                    base64String
                  );
                  this.$set(this.isBannerImageLoaded, settingId, true);
                  resolve(); // Resolve the promise once the image is processed
                };
                compressedBlob && reader.readAsDataURL(compressedBlob);
              },
              "image/jpeg",
              quality
            ); // Set the quality
          };
        });
      } catch (error) {
        console.error("Error fetching or compressing image:", error);
      }
    },
    async getImageSize(productId, imageUrl, quality = 0.6) {
      const abortController = new AbortController();
      this.abortControllers[productId] = abortController
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        await new Promise((resolve) => {
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Resize the canvas
            canvas.width = img.width * 0.5; // Adjust the scale as needed
            canvas.height = img.height * 0.5;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert canvas to blob
            canvas.toBlob(
              (compressedBlob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result;
                  this.$set(this.compressedImageUrls, productId, base64String);
                  this.$set(this.isImageLoaded, productId, true);
                  resolve(); // Resolve the promise once the image is processed
                };
                compressedBlob && reader.readAsDataURL(compressedBlob);
              },
              "image/jpeg",
              quality
            ); // Set the quality
          };
        });
      } catch (error) {
        console.error("Error fetching or compressing image:", error);
      }
    },
    async addOrderDetailFrontEnd(product) {
      this.toasts[product.id] = true;
      let orderDetail = addOrderDetail(
        this.categories[0].companies[0],
        product
      );
      this.$store.dispatch("order/addOrderDetail", orderDetail);
      await new Promise((r) => setTimeout(r, 1000));
      this.toasts[product.id] = false;
    },
  },
};
</script>
<style scoped>
.container-2 {
  width: 100%;
}

.product-item {
  box-shadow: none !important;
  background: #efeff4;
  border-radius: 6px;
}

/* .product-text-dt {
  box-shadow: 0px 3px 10px #00000033;
} */

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.round-image {
  border: 1px solid #0000001a;
  /* background: url("../assets/images.png") center/contain no-repeat; */
  object-fit: contain;
  border-radius: 100%;
  height: 140px;
  width: 140px;
}

.round-image-2 {
  border: 1px solid #0000001a;
  background: url("../assets/panasonic.png") center/contain no-repeat;
  border-radius: 100%;
  height: 140px;
  width: 140px;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .section145 .col-md-6 {
    flex: 0 0 50%;
    max-width: 49%;
  }

  .section145 {
    padding: 0px !important;
  }

  .banner-image {
    height: 381px !important;
  }

  .product-item {
    padding: 10px 3px 10px 4px !important;
  }

  .container {
    max-width: 100% !important;
  }

  .brand-card {
    margin-top: 3px !important;
    margin-bottom: -30px !important;
  }
}

@media (min-width: 1600px) {
  .section145 .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: 1850px !important;
  }
}

@media (min-width: 1200px) {
  .section145 .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: 1850px !important;
  }
}

@media (max-width: 576px) {
  .product-item {
    padding: 10px 3px 10px 4px !important;
  }

  .banner-image {
    height: 349px;
  }

  .hooper {
    height: 340px;
  }

  .offer-badge-1 img {
    height: 48px !important;
  }

  .categories-class {
    justify-content: start !important;
  }

  .brand-card {
    margin-top: -1px !important;
    margin-bottom: -10px !important;
    z-index: 1 !important;
  }

  .qty-cart .form-control {
    padding: 1px !important;
    min-width: 3em !important;
  }

  .p-3 {
    padding: 3px !important;
  }
}

.categories-class {
  justify-content: center;
}

.brand-card {
  margin-top: -85px;
  margin-bottom: -60px;
  z-index: 1;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home-switch .col-lg-2 {
    flex: 0 0 37.666667% !important;
    max-width: 55.666667% !important;
  }
}

@media (min-width: 1200px) {
  .home-switch .col-xl-1 {
    flex: 0 0 10.333333% !important;
    max-width: 12.333333% !important;
  }

  .home-switch .col-xl-2 {
    flex: 0 0 20.666667% !important;
    max-width: 18.666667% !important;
  }
}
</style>
<style>
.hooper {
  height: 405px;
}


.banner-image {
  height: 500px;
}

.card-img-top {
  height: 203px;
}

.owl-nav button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.carousel-indicators li {
  background-color: black !important;
}

.section145 .hooper-next {
  background: white;
  margin-right: -23px;
  height: 105px;
}

.section145 .hooper-prev {
  background: white;
  margin-left: -23px;
  height: 105px;
}
</style>
