<template>
  <div>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">User Dashboard</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-group">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="user-dt">
                <!-- <div class="user-img">
                  <img src="images/avatar/img-5.jpg" alt="">
                  <div class="img-add">                         
                    <input type="file" id="file">
                    <label for="file"><i class="uil uil-camera-plus"></i></label>
                  </div>
                </div> -->
                <h4>{{ (user.first_name || '')  + ' ' + (user.middle_name || '') + ' ' + (user.last_name || '') }}</h4>
                <p>+91 {{ user.phone }}</p>
                <p>Referral Code: REFX{{ user.id }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <div class="left-side-tabs">
                <div class="dashboard-left-links">
                  <!-- <router-link to="/user/dashboard" class="user-item"><i class="uil uil-apps icon__1"></i>Overview</router-link>  -->
                  <router-link to="/user/orders" class="user-item"><i class="uil uil-box"></i>My Orders</router-link> 
                  <!-- <router-link to="/user/rewards" class="user-item"><i class="uil uil-gift"></i>My Rewards</router-link>  -->
                  <router-link to="/user/wallet" class="user-item"><i class="uil uil-wallet"></i>My Wallet</router-link> 
                  <router-link to="/user/address" class="user-item"><i class="uil uil-location-point"></i>My Address</router-link> 
                  <a href="#" @click.prevent="logout" class="user-item"><i class="uil uil-exit"></i>Logout</a>
                </div>
              </div>
            </div>
            <router-view></router-view>
          </div>  
        </div>  
      </div>  
    </div>
    <!-- Body End -->
    
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      logOut: 'auth/logOut'
    }),
    logout() {
      this.logOut()
      this.$router.replace('/')
    }
  }
}
</script>