<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="all-product-grid">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div v-html="settings.contact"></div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="contact-title">
                <h2>Submit customer service request</h2>
                <p>If you have a question about our service or have an issue to report, please send a request and we will get back to you as soon as possible.</p>
              </div>
              <div class="contact-form">
                <form @submit.prevent="sendEmail">
                  <div class="form-group mt-1">
                    <label class="control-label">Full Name*</label>
                    <div class="ui search focus">
                      <div class="ui left icon input swdh11 swdh19">
                        <input class="prompt srch_explore" type="text" name="sendername" id="sendername" required="" placeholder="Your Full" v-model="form.name">                             
                      </div>
                    </div>
                  </div>
                  <div class="form-group mt-1">
                    <label class="control-label">Email Address*</label>
                    <div class="ui search focus">
                      <div class="ui left icon input swdh11 swdh19">
                        <input class="prompt srch_explore" type="email" name="emailaddress" id="emailaddress" required="" placeholder="Your Email Address" v-model="form.email">                             
                      </div>
                    </div>
                  </div>
                  <div class="form-group mt-1">
                    <label class="control-label">Subject*</label>
                    <div class="ui search focus">
                      <div class="ui left icon input swdh11 swdh19">
                        <input class="prompt srch_explore" type="text" name="sendersubject" id="sendersubject" required="" placeholder="Subject" v-model="form.subject">                             
                      </div>
                    </div>
                  </div>
                  <div class="form-group mt-1"> 
                    <div class="field">
                      <label class="control-label">Message*</label>
                      <textarea rows="2" class="form-control" id="sendermessage" name="sendermessage" required="" placeholder="Write Message" v-model="form.message"></textarea>
                    </div>
                  </div>
                  <button class="next-btn16 hover-btn mt-3" type="submit" data-btntext-sending="Sending...">{{ loading ? 'Submitting...' : 'Submit Request' }}</button>
                  <br>
                  <span style="color: green;"
                    v-if="done"
                  >Request Sent</span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  data () {
    return {
      form: {

      },
      settings: {
      },
      loading: false,
      done: false,
    }
  },
  async mounted() {
    let settings = await axios.get('settings')
    this.settings = settings.data.data[0]
  },
  methods: {
    async sendEmail() {
      this.loading = true
      await axios.post('contact-form-email', this.form)
      this.loading = false
      this.done = true
    }
  }
}
</script>

<style>
p {
  color: black !important;
}
</style>