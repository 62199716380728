<template>
  <div id="app">
    <!-- <city-modal
      v-if="!city.name && $route.path!='/payment' && $route.path!='/thankyou'"
    ></city-modal> -->

    <!-- <template
      v-else
    > -->
    <template>
      <SearchModel @search="handleSearch" />

      <CartSidebar />

      <Header @search="handleSearch" />

      <router-view :searchKeyword="searchKeyword" />

      <Footer />
    </template>
  </div>
</template>

<script type="text/javascript">
import Header from '@/views/layout/header'
import Footer from '@/views/layout/footer'
import SearchModel from '@/views/layout/search-modal'
import CartSidebar from '@/views/layout/cart-sidebar'
import { mapGetters, mapActions } from 'vuex'
// import CityModal from '@/views/layout/city-modal'

export default {
  data() {
    return {
      searchKeyword: "",
    };
  },
  computed: {
    ...mapGetters({
      city: 'city/getCity'
    })
  },
  components: {
    Header,
    Footer,
    SearchModel,
    CartSidebar,
    // CityModal
  },
  mounted() {
    this.loadExternalScripts()
    this.fetchcities()
    this.fetchorder()
  },
  methods: {
    loadExternalScripts() {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', '/js/custom.js')
      document.head.appendChild(externalScript)
    },
    ...mapActions({
      fetchCities: 'city/fetchCities',
      fetchOrder: 'order/fetchOrder',
    }),
    fetchcities() {
      this.fetchCities()
    },
    fetchorder() {
      this.fetchOrder()
    },
    handleSearch(keyword) {
      console.log(keyword);
      console.log('keyword');
      if (keyword) {
        this.$router.push({
          path: `/categories/1/stores/1`,
          query: { keyword: keyword },
        });
      } else {
        this.$router.push('/categories/1/stores/1');
        // this.$router.go();
      }
    },
  }
}
</script>
