import axios from 'axios'

export default {
  namespaced: true,
  state: {
    categories: []
  },
  getters: {
    getCategories(state) {
      return state.categories
    }
  },
  mutations: {
    SET_CATEGORIES(state, data) {
      state.categories = data
    }
  },
  actions: {
    async fetchCategories({ rootState, commit }) {
      let cityId = rootState.city.city.id
      let response = await axios.get(`/categories?cityId=${cityId}&isCustomerSide=true`)
      commit('SET_CATEGORIES', response.data.data)
    },
  }
}