<template>
  <section>
    <div class="wrapper">
      <b-container class="Rectangle2979" style="background: white">
        <b-row>
          <b-container class="pb-0">
            <b-col sm="12" class="d-flex py-4" style="flex-direction: column">
              <div>
                <strong style="font-size: 14px">Select your House Type</strong>
              </div>
              <div class="d-flex text-center" style="justify-content: space-around">
                <b-col sm="2" class="section" v-for="(house, index) in HouseTypes" :key="index"
                  @click="changeColorHouse(index + 1, house.value)"
                  :class="{ active: activeSectionHouse === index + 1 }">
                  {{ house.text }}
                </b-col>
              </div>
            </b-col>
          </b-container>
          <b-container class="pb-0">
            <b-col sm="12" class="d-flex py-4" style="flex-direction: column; border-top: 0.15rem dotted black">
              <div>
                <strong>Select your Product Type</strong>
              </div>
              <div class="scrollable-container d-flex text-center" style="justify-content: space-around">
                <b-col cols="4" xl="2" lg="2" md="2" sm="3" class="section" v-for="(producttype, index) in ProductTypes"
                  :key="index" @click="changeColorProductType(index + 1, producttype.value)"
                  :class="{ active: activeSectionProductType == index + 1 }">
                  {{ producttype.text }}
                </b-col>
              </div>
            </b-col>
          </b-container>
          <b-container class="pb-0" v-if="isShowBrand">
            <b-col sm="12" class="d-flex py-4" style="flex-direction: column; border-top: 0.15rem dotted black">
              <div>
                <strong>Select Your Price Range</strong>
              </div>
              <div v-if="form.house_type == '1 BHK'" class="scrollable-container d-flex text-center"
                style="justify-content: space-around">
                <b-col sm="2" class="section" v-for="(amount, index) in amounts['1bhk']" :key="index"
                  @click="changeColorAmount(index + 1, amount.value)"
                  :class="{ active: activeSectionAmount === index + 1 }">
                  {{ amount.text }}
                </b-col>
              </div>
              <div v-if="form.house_type == '2 BHK'" class="scrollable-container d-flex text-center"
                style="justify-content: space-around">
                <b-col sm="2" class="section" v-for="(amount, index) in amounts['2bhk']" :key="index"
                  @click="changeColorAmount(index + 1, amount.value)"
                  :class="{ active: activeSectionAmount === index + 1 }">
                  {{ amount.text }}
                </b-col>
              </div>
              <div v-if="form.house_type == '3 BHK'" class="scrollable-container d-flex text-center"
                style="justify-content: space-around">
                <b-col sm="2" class="section" v-for="(amount, index) in amounts['3bhk']" :key="index"
                  @click="changeColorAmount(index + 1, amount.value)"
                  :class="{ active: activeSectionAmount === index + 1 }">
                  {{ amount.text }}
                </b-col>
              </div>
            </b-col>
            <!-- <b-col sm="12" class="d-flex" style="flex-direction: column">
              <div class="scrollable-container d-flex text-center brand-class">
                <b-col
                  sm="2"
                  class="section"
                  v-for="(brand, index) in brands"
                  :key="index"
                  @click="changeColorBrand(index + 1, brand.value)"
                  :class="{ active: activeSectionBrand === index + 1 }"
                >
                  {{ brand.text }}
                </b-col>
              </div>
            </b-col> -->
          </b-container>
          <b-container v-if="isCustomize" class="p-0">
            <div class="col-md-12 p-2">
              <div class="card mt-2">
                <!-- End Export to Excel -->
                <b-overlay :show="isAdding" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                  <div v-if="bhkmappings.length > 0">
                    <b-row class="mt-4 vertical-divider mx-0">
                      <b-col v-for="(brand, i) in bhkmappings" :key="i" cols="6" class="wires-class">
                        <b-col v-if="form.product_type_id == 2" cols="12" class="pb-2 sticky-container"
                          style="background: white;">
                          <b-row class="mx-0">
                            <b-col class="wire-brand-image-parent-class" cols="12" xl="3" lg="3" md="12">
                              <img class="wire-brand-image" :src="brand.imagepath
                                ? `${mediaURL}${brand.imagepath}`
                                : '/images/product/img-1.jpg'
                                " alt="" style="width: 144px; margin-top: 8px" />
                            </b-col>
                            <b-col class="wire-total-price-parent-class" cols="12" xl="5" lg="6" md="12"
                              style="align-items: center; padding-top: 15px">
                              <div class="wire-total-price" style="font-size: 20px">
                                <span v-if="form.product_type_id == 2" style="color: #f55d2c">Total: ₹
                                  {{
                                    getTotalPrice(brand.sub_3_category)
                                    | twoDecimal
                                  }}
                                </span>
                                <span v-else style="color: #f55d2c">Total: ₹
                                  {{
                                    getTotalPrice(brand.products) | twoDecimal
                                  }}
                                </span>
                              </div>
                            </b-col>
                            <b-col cols="12" xl="4" lg="4" md="12" class="p-3">
                              <b-button class="float-button  float-right" color="#ff0505"
                                @click="addAllOrderDetailFrontEnd(brand)" style="
                                  color: white;
                                  background: black;
                                  border-radius: 40px;
                                  font-size: 17px;
                                  width: 140px;
                                ">
                                <span v-if="!isAdding" style="font-size: 13px">Add package to cart</span>
                                <span v-else>
                                  <b-spinner small type="grow"></b-spinner>
                                  Adding...</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col v-else cols="12" class="sticky-container" style="background: white;">
                          <b-row class="mx-0" style="align-items: center">
                            <b-col cols="12" xl="4" lg="4" md="12" class="px-0">
                              <img :src="brand.sub_3_category.imagepath
                                ? `${mediaURL}${brand.sub_3_category.imagepath}`
                                : '/images/product/img-1.jpg'
                                " alt="" style="width: 140px; margin-top: 0px;height: 74px;" />
                            </b-col>
                            <b-col cols="12" xl="4" lg="4" md="12" class="px-0" style="align-items: center">
                              <div>
                                <div class="text-start" style="font-size: 20px">
                                  <span style="color: #f55d2c">Total: ₹
                                    {{
                                      getTotalPrice(
                                        brand.sub_3_category.products
                                      ) | twoDecimal
                                    }}
                                  </span>
                                </div>
                              </div>
                            </b-col>
                            <b-col cols="12" xl="4" lg="4" md="12" class="p-3">
                              <b-button class="float-button float-right" color="#ff0505" @click="
                                addAllOrderDetailFrontEnd(
                                  brand.sub_3_category.products
                                )
                                " style="
                                  color: white;
                                  background: black;
                                  border-radius: 40px;
                                  font-size: 17px;
                                  width: 159px;
                                ">
                                <span v-if="!isAdding" style="font-size: 13px">Add package to cart</span>
                                <span v-else>
                                  <b-spinner small type="grow"></b-spinner>
                                  Adding...</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        <!-- <b-row> -->
                        <template v-if="form.product_type_id == 2">
                          <b-col cols="12" class="wire-sub-class" v-for="(subCategory, index) in brand.sub_3_category"
                            :key="index">
                            <div v-for="(
                                productsBySize, size
                              ) in subCategory.products" :key="size">
                              <h3 class="wire-h3" style="
                                  font-weight: 500;
                                  background: #0000001c;
                                  padding: 6px;
                                ">
                                {{ size }} ({{ subCategory.name }})
                              </h3>
                              <!-- Display size as a heading -->
                              <hooper :itemsToShow="itemsToShow" :itemsToSlide="1" class="customize-hooper">
                                <slide v-for="(mapping, idx) in productsBySize"
                                  :key="`product-${subCategory.sub_3_category_id}-${idx}`" cols="6" xl="3" lg="3" md="6"
                                  sm="6" xs="6" style="margin-bottom: 34px">
                                  <div class="product-item mb-30" style="height: 260px; position: relative">
                                    <b-icon @click="removeProduct(mapping.product.id)" icon="x-circle-fill" style="
                                        position: absolute;
                                        right: 0px;
                                        z-index: 1;
                                      "></b-icon>
                                    <router-link
                                      :to="`/categories/${categories[0].id}/stores/${categories[0].companies[0].id}/products/${mapping.product.id}`">
                                      <b-skeleton-img v-if="!isImageLoaded[mapping.product.id]
                                      " height="136px"></b-skeleton-img>
                                      <img v-show="isImageLoaded[mapping.product.id]
                                        " @load="
                                          loadImage(
                                            mapping.product.id,
                                            `${'https://ik.imagekit.io/edefqapdy/'}${mapping.product.imagepath1
                                            }`
                                          )
                                          " :src="mapping.product.imagepath1
                                            ? `${mediaURL}${mapping.product.imagepath1}`
                                            : '/images/product/img-1.jpg'
                                            " alt="" style="height: 136px" />
                                    </router-link>
                                    <div class="product-text-dt">
                                      <h4>{{ mapping.product.name }}</h4>
                                      <div class="product-price" v-if="mapping.product.product_prices[0]
                                        .offer_price !=
                                        mapping.product.product_prices[0]
                                          .price
                                      ">
                                        ₹
                                        <span v-if="mapping.quantity != 0">
                                          {{
                                            (mapping.selling_price *
                                              mapping.quantity)
                                            | twoDecimal
                                          }}
                                        </span>
                                        <span v-else>
                                          {{
                                            mapping.selling_price | twoDecimal
                                          }}
                                        </span>
                                        <br>
                                        <span class="real-price">₹
                                          {{
                                            mapping.product.product_prices[0]
                                              .price | twoDecimal
                                          }}</span>
                                        <span class="off-percent ml-0" v-if="getDiscountPercent(
                                          mapping.product.product_prices[0]
                                            .price,
                                          mapping.product.product_prices[0]
                                            .offer_price
                                        ) > 0
                                        ">
                                          {{
                                            getDiscountPercent(
                                              mapping.product.product_prices[0]
                                                .price,
                                              mapping.product.product_prices[0]
                                                .offer_price
                                            )
                                          }}% OFF
                                        </span>
                                        <br />
                                        <div class="quantity buttons_added float-right">
                                          <input type="button" value="-" class="minus minus-btn" @click="
                                            decrementProduct(
                                              mapping.product.id
                                            )
                                            " />
                                          <input type="number" step="1" :value="mapping.quantity"
                                            class="input-text qty text" />
                                          <input type="button" value="+" class="plus plus-btn" @click="
                                            incrementProduct(
                                              mapping.product.id
                                            )
                                            " />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </slide>
                                <hooper-navigation slot="hooper-addons"></hooper-navigation>
                              </hooper>
                            </div>
                          </b-col>
                          <hr />
                        </template>
                        <b-col v-else cols="12" class="px-4">
                          <!-- <hooper :itemsToShow="itemsToShow" :itemsToSlide="1"
                            class="customize-hooper other-product-class"> -->
                          <b-row>
                            <b-col class="other-product-class" cols="12" xl="6" lg="6" md="12*-" sm="12" xs="12" v-for="(mapping, s) in brand.sub_3_category
                              .products" :key="`product${s}`">
                              <div class="product-item mb-8" style="height: 309px; position: relative">
                                <b-icon @click="removeProduct(i, s)" icon="x-circle-fill" style="
                                    position: absolute;
                                    right: 0px;
                                    z-index: 1;
                                  "></b-icon>
                                <router-link
                                  :to="`/categories/${categories[0].id}/stores/${categories[0].companies[0].id}/products/${mapping.product.id}`"
                                  class="product-img">
                                  <b-skeleton-img v-if="!isImageLoaded[mapping.product.id]"
                                    height="170px"></b-skeleton-img>
                                  <img v-show="isImageLoaded[mapping.product.id]" @load="
                                    loadImage(
                                      mapping.product.id,
                                      `${'https://ik.imagekit.io/edefqapdy/'}${mapping.product.imagepath1
                                      }`
                                    )
                                    " :src="mapping.product.imagepath1
                                      ? `${mediaURL}${mapping.product.imagepath1}`
                                      : '/images/product/img-1.jpg'
                                      " alt="" style="height: 180px" />
                                </router-link>
                                <div class="product-text-dt">
                                  <h4>
                                    {{ mapping.product.name }}
                                  </h4>
                                  <h4 class="mb-0">
                                    {{
                                      mapping.product.sub_3_category
                                        ? mapping.product.sub_3_category.name
                                        : ""
                                    }}
                                    {{ mapping.product.catelog_no || "" }}
                                  </h4>
                                  <div class="product-price" v-if="mapping.product.product_prices[0]
                                    .offer_price !=
                                    mapping.product.product_prices[0].price
                                  ">
                                    ₹
                                    <span v-if="mapping.quantity != 0">
                                      {{
                                        (mapping.selling_price *
                                          mapping.quantity)
                                        | twoDecimal
                                      }}
                                    </span>
                                    <span v-else>
                                      {{ mapping.selling_price | twoDecimal }}
                                    </span>
                                    <span class="real-price">₹
                                      {{
                                        mapping.product.product_prices[0].price
                                        | twoDecimal
                                      }}
                                    </span>
                                    <br />
                                    <div class="quantity buttons_added float-right">
                                      <input type="button" value="-" class="minus minus-btn"
                                        @click="decrementProduct(i, s)" />
                                      <input type="number" step="1" :value="mapping.quantity"
                                        class="input-text qty text" />
                                      <input type="button" value="+" class="plus plus-btn"
                                        @click="incrementProduct(i, s)" />
                                    </div>
                                    <span class="off-percent ml-0" v-if="getDiscountPercent(
                                      mapping.product.product_prices[0]
                                        .price,
                                      mapping.product.product_prices[0]
                                        .offer_price
                                    ) > 0
                                    ">
                                      {{
                                        getDiscountPercent(
                                          mapping.product.product_prices[0]
                                            .price,
                                          mapping.product.product_prices[0]
                                            .offer_price
                                        )
                                      }}% OFF
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>

                          <!-- <hooper-navigation slot="hooper-addons"></hooper-navigation>
                          </hooper> -->
                        </b-col>
                      </b-col>
                      <!-- </b-row> -->
                    </b-row>
                  </div>
                  <div v-else-if="isLoading" class="loader-class">
                    <img style="width: 100px" src="../../assets/loader.gif" alt="" />
                  </div>
                  <div class="no-results-message" v-else>
                    <img style="width: 100px" src="../../assets/not-found.png" alt="" />
                  </div>
                </b-overlay>
              </div>
            </div>
          </b-container>
        </b-row>
        <b-row v-if="isCustomize == false">
          <b-col sm="12" class="d-flex mt-4" style="justify-content: center">
            <b-button class="mx-4 px-4 py-3" color="#ff0505" @click="fetchProductName"
              :disabled="form.house_type == '' || form.product_type_id == ''" style="
                color: white;
                background: black;
                border-radius: 60px;
                font-size: 12px;
              ">
              <span>Let's Customize</span>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { addPackageOrderDetail } from "@/helpers/index.js";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  data() {
    return {
      form: {
        house_type: "",
        brand_id: "",
        amount: "",
        product_type_id: "",
        IncludingTaxAmount: 0,
        isCart: true,
      },
      brandImage: "",
      toasts: [],
      bhkmappings: [],
      isImageLoaded: [],
      compressedImageUrls: [],
      imageQueue: [],
      isProcessingQueue: false,
      totalUnits: 0,
      HouseTypes: [
        { text: "1 BHK", value: "1 BHK" },
        { text: "2 BHK", value: "2 BHK" },
        { text: "3 BHK", value: "3 BHK" },
        { text: "4 BHK", value: "4 BHK" },
      ],
      ProductTypes: [],
      brands: [],
      amounts: {
        "1bhk": [
          { text: "5000-7000", value: "5000-7000" },
          { text: "7000-10000", value: "7000-10000" },
          { text: "10000-15000", value: "10000-15000" },
          { text: "12000-18000", value: "12000-18000" },
        ],
        "2bhk": [
          { text: "8000-11000", value: "8000-11000" },
          { text: "11000-15000", value: "11000-15000" },
          { text: "15000-20000", value: "15000-20000" },
          { text: "18000-22000", value: "18000-22000" },
        ],
        "3bhk": [
          { text: "9000-12000", value: "9000-12000" },
          { text: "12000-18000", value: "12000-18000" },
          { text: "18000-22000", value: "18000-22000" },
          { text: "22000-25000", value: "22000-25000" },
        ],
      },
      orderstatus: [],
      activeSectionHouse: null,
      activeSectionProductType: null,
      activeSectionBrand: null,
      activeSectionAmount: null,
      isCustomize: false,
      isWatchActive: false,
      isLoading: false,
      isAdding: false,
      isShowBrand: false,
      isComponentDestroyed: false,
      abortControllers: {},
    };
  },
  computed: {
    ...mapGetters({
      categories: "category/getCategories",
      mediaURL: "settings/getMediaUrl",
      order: "order/getOrder",
    }),
    itemsToShow() {
      if (window.innerWidth >= 1600 && this.form.product_type_id == 2) {
        return 3;
      } else if (window.innerWidth >= 1600 && this.form.product_type_id != 2) {
        return 3;
      } else if (
        window.innerWidth < 1600 &&
        window.innerWidth > 1024 &&
        this.form.product_type_id == 2
      ) {
        return 3;
      } else if (window.innerWidth < 1024 && this.form.product_type_id == 2) {
        return 2;
      } else if (window.innerWidth < 920 && this.form.product_type_id != 2) {
        return 2;
      } else {
        return 3;
      }
    },
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  watch: {
    "form.house_type": {
      handler() {
        if (this.isWatchActive) {
          this.fetchProductName();
        }
      },
      deep: true, // Watch deeply nested properties
    },
    "form.brand_id": {
      handler() {
        if (this.isWatchActive) {
          this.fetchProductName();
        }
      },
      deep: true, // Watch deeply nested properties
    },
    "form.amount": {
      handler() {
        if (this.isWatchActive) {
          this.fetchProductName();
        }
      },
      deep: true, // Watch deeply nested properties
    },
    "form.product_type_id": {
      handler() {
        if (this.isWatchActive) {
          this.totalUnits = 0;
          this.bhkmappings = [];
          this.form.brand_id = "";
          this.form.amount = "";
          this.activeSectionBrand = null;
          this.activeSectionAmount = null;
          this.fetchProductName();
        }
      },
      deep: true, // Watch deeply nested properties
    },
  },
  mounted() {
    this.getMaster();
  },
  beforeDestroy() {
    console.log("Component is being destroyed. Cleaning up ongoing processes...");
    for (const productId in this.abortControllers) {
      if (this.abortControllers[productId]) {
        this.abortControllers[productId].abort(); // Abort the fetch request
      }
    }
    // Clear the image queue'
    this.imageQueue = [];
    this.isProcessingQueue = false;
  },
  methods: {
    async getMaster(ProductTypeId) {
      console.log("this.isWatchActive", this.isWatchActive);
      this.ProductTypes = [];
      this.brands = [];
      let subCategoryId = ProductTypeId != null ? ProductTypeId : "";
      let masters = await axios.get(
        `orders/masters?sub_category_id=${subCategoryId}`
      );
      masters = masters.data;
      masters.productType.forEach((producttype) => {
        this.ProductTypes.push({
          id: producttype.id,
          text: producttype.name,
          value: producttype.id,
        });
      });
      masters.Brand.forEach((brand) => {
        this.brands.push({
          id: brand.id,
          text: brand.name,
          value: brand.id,
        });
      });
    },
    async fetchProductName() {
      console.log('image-que', this.imageQueue.length);

      for (const productId in this.abortControllers) {
        if (this.abortControllers[productId]) {
          this.abortControllers[productId].abort(); // Abort the fetch request
        }
      }
      // Clear the image queue'
      this.imageQueue = [];
      this.isProcessingQueue = false;
      console.log('image-que', this.imageQueue.length);
      this.bhkmappings = [];
      this.isLoading = true;
      this.form.IncludingTaxAmount = 0;
      this.isWatchActive = true;
      this.isCustomize = true;
      if (
        this.form.house_type &&
        // this.form.amount &&
        this.form.product_type_id
      ) {
        let query =
          "&house_type=" +
          this.form.house_type +
          "&product_type_id=" +
          this.form.product_type_id +
          "&brand_id=" +
          this.form.brand_id +
          "&amount=" +
          this.form.amount;
        try {
          let response = await axios.get(`/products?${query}`, {
            headers: {
              "company-id": this.categories[0].companies[0].id,
            },
          });
          this.bhkmappings = response.data.data;
          this.isLoading = false;
        } catch (error) {
          console.error("Error fetching product name:", error);
        }
      } else {
        this.isLoading = false;
      }
    },
    async addAllOrderDetailFrontEnd(packages) {
      this.isAdding = true;
      if (this.form.product_type_id == 2) {
        for (let subCategory of packages.sub_3_category) {
          // Iterate over each size group within the sub-category
          for (let size in subCategory.products) {
            // Iterate over each product in the size group
            for (let product of subCategory.products[size]) {
              if (!product.product_price) {
                product.product.product_price = {};
              }
              // Append the selectedQuantity property
              product.product.product_price.selectedQuantity = product.quantity;
              product.product.product_price.price = product.selling_price;
              let orderDetail = addPackageOrderDetail(
                this.categories[0].companies[0],
                product.product
              );

              await this.$store.dispatch("order/addOrderDetail", orderDetail);
              await new Promise((r) => setTimeout(r, 1000));
            }
          }
        }
      } else {
        for (let pack of packages) {
          // for (let product of pack.product) {
          if (!pack.product.product_price) {
            pack.product.product_price = {};
          }
          // Append the selectedQuantity property
          pack.product.product_price.selectedQuantity = pack.quantity;
          pack.product.product_price.price = pack.selling_price;
          let orderDetail = addPackageOrderDetail(
            this.categories[0].companies[0],
            pack.product
          );
          console.log("product", pack.product);

          await this.$store.dispatch("order/addOrderDetail", orderDetail);
          await new Promise((r) => setTimeout(r, 1000));
          // }
        }
      }
      this.isAdding = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.isUserCustomization = true;
        this.form.is_user_customization = true;
        this.form.user_id = this.user.id ? this.user.id : "";
        this.form.products = this.bhkmappings;
        let ordersData = await axios.post("/orders", this.form);
        this.ordersData = ordersData.data.data;
        this.isLoading = false;
        this.$router.push("/carts");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async loadImage(productId, imageUrl) {
      this.isLoading = true;
      const cleanedUrl = imageUrl.replace(/\/bb/, "");
      const encodedUrl = encodeURI(cleanedUrl);
      if (!encodedUrl.includes("/null")) {
        this.imageQueue.push({ productId, imageUrl: encodedUrl });
        if (!this.isProcessingQueue) {
          console.log('this.imageQueue After', this.imageQueue.length);
          this.processImageQueue();
        }
      }
      this.isLoading = false;
    },
    async processImageQueue() {
      this.isProcessingQueue = true;

      while (this.imageQueue.length > 0) {
        const { productId, imageUrl } = this.imageQueue.shift();
        await this.getImageSize(productId, imageUrl);
      }

      this.isProcessingQueue = false;
    },
    async getImageSize(productId, imageUrl, quality = 0.6) {
      const abortController = new AbortController();
      this.abortControllers[productId] = abortController
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        await new Promise((resolve) => {
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Resize the canvas
            canvas.width = img.width * 0.5; // Adjust the scale as needed
            canvas.height = img.height * 0.5;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert canvas to blob
            canvas.toBlob(
              (compressedBlob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  const base64String = reader.result;
                  this.$set(this.compressedImageUrls, productId, base64String);
                  this.$set(this.isImageLoaded, productId, true);
                  resolve(); // Resolve the promise once the image is processed
                };
                compressedBlob && reader.readAsDataURL(compressedBlob);
              },
              "image/jpeg",
              quality
            ); // Set the quality
          };
        });
      } catch (error) {
        console.error("Error fetching or compressing image:", error);
      }
    },
    getTotalPrice(products) {
      let total = 0;
      if (this.form.product_type_id == 2) {
        let sub3Categories = products;
        sub3Categories.forEach((sub3Category) => {
          if (sub3Category.products) {
            // Check if products is an object and iterate over its keys (sizes)
            for (const size in sub3Category.products) {
              if (
                Object.prototype.hasOwnProperty.call(
                  sub3Category.products,
                  size
                )
              ) {
                // Calculate the total for the products of this size
                total += sub3Category.products[size].reduce(
                  (groupTotal, product) => {
                    return (
                      groupTotal +
                      parseFloat(product.selling_price) * product.quantity
                    );
                  },
                  0
                );
              }
            }
          }
        });
        return parseFloat(total.toFixed(2));
      } else {
        total = products.reduce((total, mapping) => {
          return total + mapping.selling_price * mapping.quantity;
        }, 0);
        return parseFloat(total.toFixed(2));
      }
    },
    changeColorHouse(section, Housetype) {
      if (this.form.product_type_id == 1) {
        this.form.amount = null;
        this.activeSectionAmount = null;
        this.isWatchActive = false;
        this.isCustomize = false;
      }
      this.activeSectionHouse = section;
      this.form.house_type = Housetype;
    },
    changeColorProductType(section, Producttype) {
      console.log(" Producttype", Producttype);

      this.isShowBrand = false;
      // this.getMaster(Producttype);
      this.activeSectionProductType = section;
      this.form.product_type_id = Producttype;
      if (this.form.product_type_id == 1) {
        this.isShowBrand = true;
      }
    },
    changeColorBrand(section, brand) {
      console.log(brand);
      this.form.brand_id = brand;
      const foundCategory = this.categories[0].sub_categories_filter.find(
        (e) => e.id == this.form.product_type_id
      );
      let sub2category = foundCategory.sub_2_categories.find(
        (e) => e.id == brand
      );
      console.log(sub2category);
      this.brandImage = sub2category ? sub2category.imagepath : null;
      this.activeSectionBrand = section;
    },
    changeColorAmount(section, amount) {
      console.log(amount);
      this.form.amount = amount;
      this.activeSectionAmount = section;
    },
    getDiscountPercent(originalPrice, offerPrice) {
      if (!originalPrice || !offerPrice) return 0;
      const discount = ((originalPrice - offerPrice) / originalPrice) * 100;
      return discount.toFixed(2);
    },
    decrementProduct(brandIndex, productIndex) {
      console.log("product_id", brandIndex);
      if (this.form.product_type_id == 2) {
        for (const subCategory of this.bhkmappings) {
          for (const sub3Category of subCategory.sub_3_category) {
            for (const sizeGroup in sub3Category.products) {
              const productList = sub3Category.products[sizeGroup];
              const product = productList.find(
                (p) => p.product_id == brandIndex
              );
              if (product && product.quantity > 0) {
                product.quantity -= 1; // Decrement quantity
                return; // Exit once product is found and updated
              }
            }
          }
        }
      } else {
        if (
          this.bhkmappings[brandIndex].sub_3_category.products[productIndex]
            .quantity > 0
        ) {
          this.bhkmappings[brandIndex].sub_3_category.products[
            productIndex
          ].quantity -= 1;
        }
      }
    },
    incrementProduct(brandIndex, productIndex) {
      if (this.form.product_type_id == 2) {
        for (const subCategory of this.bhkmappings) {
          for (const sub3Category of subCategory.sub_3_category) {
            for (const sizeGroup in sub3Category.products) {
              const productList = sub3Category.products[sizeGroup];
              const product = productList.find(
                (p) => p.product_id == brandIndex
              );
              if (product) {
                product.quantity += 1; // Increment quantity
                return; // Exit once product is found and updated
              }
            }
          }
        }
      } else {
        this.bhkmappings[brandIndex].sub_3_category.products[
          productIndex
        ].quantity += 1;
      }
    },
    removeProduct(brandIndex, productIndex) {
      if (this.form.product_type_id == 2) {
        for (const subCategory of this.bhkmappings) {
          for (const sub3Category of subCategory.sub_3_category) {
            for (const sizeGroup in sub3Category.products) {
              const productList = sub3Category.products[sizeGroup];

              const productIndex = productList.findIndex(
                (p) => p.product_id == brandIndex
              ); // Get index of product
              console.log("productIndex", productIndex);
              if (productIndex != -1) {
                const product = productList[productIndex];
                if (product.quantity > 0) {
                  product.quantity -= 1; // Decrement quantity
                }
                // Remove the product if quantity is zero
                if (product.quantity === 0) {
                  productList.splice(productIndex, 1); // Remove product from the list
                }
                return; // Exit once product is found and removed
              }
            }
          }
        }
      } else {
        this.bhkmappings[brandIndex].sub_3_category.products.splice(
          productIndex,
          1
        );
        if (this.bhkmappings[brandIndex].sub_3_category.products.length === 0) {
          this.bhkmappings.splice(brandIndex, 1);
        }
      }
    },
  },
};
</script>
<style scoped>
.sticky-container {
  position: sticky;
  top: 120px;
  z-index: 1000;
}

table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.wire-total-price {
  text-align: center;
}

.product-text-dt {
  padding: 4px;
}

.qty-cart {
  margin-top: 7px;
}

.product-price {
  margin-top: 7px;
}

.hooper {
  height: 336px;
}

@media (min-width: 768px) {
  .other-product-class {
    padding: 20px;
  }


}
</style>
<style>
.v-slide-group__content {
  justify-content: space-around;
}

.section {
  cursor: pointer;
  padding: 6px;
  margin: 5px;
  border: 1px solid #000000c4;
  border-radius: 3px;
  font-size: 14px;
  background-color: white;
}

.section.active {
  background-color: #f5c209;
  color: white;
}

.scrollable-container {
  display: flex;
  overflow-x: auto;
  max-width: 100%;
}

.total-price {
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  padding: 15px 45px;
}

.customize-hooper .hooper-slide {
  padding: 10px;
}

.customize-hooper .hooper-next {
  position: absolute;
  top: 118px;
  background: none;
  margin-right: 7px;
}

.customize-hooper .hooper-prev {
  position: absolute;
  top: 118px;
  left: 16px;
  background: none;
  margin-right: 15px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .wire-sub-class .product-item {
    height: 278px !important;
  }

  .wire-total-price {
    text-align: start !important;
  }
}
</style>
