<template>
 <div class="col-lg-9 col-md-8">
    <div class="dashboard-right">
      <div class="row">
        <div class="col-md-12">
          <div class="main-title-tab">
            <h4><i class="uil uil-wallet"></i>My Wallet</h4>
          </div>
        </div>                
        <div class="col-lg-6 col-md-12">
          <div class="pdpt-bg">
            <div class="reward-body-dtt">
              <span class="rewrd-title">My Balance</span>
              <h4 class="cashbk-price">₹ {{ todayBalance }}</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="pdpt-bg">
            <div class="pdpt-title">
              <h4>Cashbacks and Rewards</h4>
            </div>
            <div class="active-offers-body">
              <div class="table-responsive">
                <table class="table ucp-table earning__table">
                  <thead class="thead-s">
                    <tr>
                      <th scope="col">Offer</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Available For (In Days)</th>
                      <th scope="col">Status</th>               
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(wallet, w) in user_wallets"
                      :key="`wallet${w}`"
                    >                    
                      <td>{{ wallet.source }}</td>  
                      <td>₹ {{ wallet.amount }}</td>  
                      <td>{{ wallet.created_at }}</td>  
                      <td>{{ wallet.expiry }}</td>
                      <td>{{ wallet.no_of_days }}</td>
                      <td v-if="wallet.status == 'Activated'"><b style="color: green;">{{ wallet.status }}</b></td>  
                      <td v-if="wallet.status == 'Expired'"><b style="color: red;" >{{ wallet.status }}</b></td>  
                    </tr>
                  </tbody>        
                </table>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      user_wallets: [],
      walletBalance: 0,
      todayBalance: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let todayBalance = 0
      let user_wallets = await axios.get(`/user_wallets?userId=${this.user.id}`)
      user_wallets = user_wallets.data.data
      let now = moment().format('DD-MM-YYYY')
      user_wallets.forEach((wallet, i) => {
        let expiry = '' 
        let next = ''
        expiry = moment(wallet.created_at).add(wallet.no_of_days, 'days').format('DD-MM-YYYY')
        if(i < (user_wallets.length - 1))
          next = moment(user_wallets[i+1].created_at).add(user_wallets[i+1].no_of_days, 'days').format('DD-MM-YYYY')
        else
          next = moment().format('DD-MM-YYYY')
        if(expiry >= next) {
          this.walletBalance = this.walletBalance + parseFloat(wallet.amount)
        } 
        if(expiry >= now)
            todayBalance = todayBalance + parseFloat(wallet.amount)
        if(wallet.source == 'Amount Used') {
          this.walletBalance = this.walletBalance + parseFloat(wallet.amount)
        }
      })
      if(this.walletBalance < todayBalance)
        this.todayBalance = this.walletBalance
      else
        this.todayBalance = todayBalance


      user_wallets.forEach(wallet => {
        let now = moment().format('DD-MM-YYYY')
        let expiry = moment(wallet.created_at).add(wallet.no_of_days, 'days').format('DD-MM-YYYY')
        wallet.expiry = moment(wallet.created_at).add(wallet.no_of_days, 'days').format('YYYY-MM-DD')
        if(expiry > now) {
          wallet.status = 'Activated'
          // this.walletBalance = this.walletBalance + parseFloat(wallet.amount)
        } else if(wallet.source == 'Amount Used') {
          wallet.expiry = ''
          wallet.status = ''
          // this.walletBalance = this.walletBalance + parseFloat(wallet.amount)
        }
        else
          wallet.status = 'Expired'
      })
      


      this.user_wallets = user_wallets.reverse()
      
      // console.log(this.user_wallets[0].created_at)
      // console.log(moment(this.user_wallets[0].created_at).add(2, 'days').format('DD-MM-YYYY'))
      // console.log(moment().format('DD-MM-YYYY'))
      // if(expiry > now)
      //   console.log('expired')
      // let date = moment(this.user_wallets[0].created_at).format('DD-MM-YYYY')

    }
  }
}
</script>