export function selectDefaultProductPrice(product, order) {
  product.selectedProductPriceId = ''
  let defaultProductPrice = product.product_prices.find(price => price.is_default == 1)
  if (defaultProductPrice) {
    // orderDetail is used to get the quantity of the pack in the cart
    let orderDetail = order.order_details.find(d => d.product_id == product.id && (d.product_pack == defaultProductPrice.product_pack.value + ' ' + defaultProductPrice.product_pack.unit.name))
    updateProductPrice(product, defaultProductPrice, orderDetail ? orderDetail.quantity : 0)
  } else {
    // orderDetail is used to get the quantity of the pack in the cart
    let orderDetail = order.order_details.find(d => d.product_id == product.id && (d.product_pack == product.product_prices[0].product_pack.value + ' ' + product.product_prices[0].product_pack.unit.name))
    updateProductPrice(product, product.product_prices[0], orderDetail ? orderDetail.quantity : 0)
  }
}

export function updateProductPrice(product, price, selectedQuantity) {
  product.selectedProductPriceId = price.id;
  product.product_price = {
    price: price.price,
    offer_price: price.offer_price || price.price,
    cashback_price: price.cashback_price,
    pack_value: price.product_pack.value,
    unit: price.product_pack.unit.name,
    offer_percent: price.offer_price ? Math.round((1 - (price.offer_price / price.price)) * 100) : 0,
    selectedQuantity: selectedQuantity,
  };
}

export function updateProductPriceByDropDown(product, priceId, order) {
  let price = product.product_prices.find(
    (product_price) => product_price.id == priceId
  );
  // orderDetail is used to get the quantity of the pack in the cart
  let orderDetail = order.order_details.find(d => d.product_id == product.id && (d.product_pack == price.product_pack.value + ' ' + price.product_pack.unit.name))
  updateProductPrice(product, price, orderDetail ? orderDetail.quantity : 0)
}

// Order
export function addOrderDetail(store, product) {
  let selectedQuantity = (product.product_price && product.product_price.selectedQuantity != null)
    ? product.product_price.selectedQuantity
    : product.product_packs[0].value;

  console.log('product', selectedQuantity);
  let orderDetail = {
    store: Object.assign({}, store),
    product: Object.assign({}, product),
    product_id: product.id,
    quantity: selectedQuantity,
    order_detail_cost: selectedQuantity * (product.product_prices ? (product.product_prices[0].offer_price ?? product.product_prices[0].price) : 0),
    // product_pack: product.product_prices ? (product.product_prices[0].product_pack.value + ' ' + (product.product_prices[0].unit != null ? product.product_prices[0].unit : "")) : '',
    cashback: selectedQuantity * ((product.product_prices && product.product_prices[0].cashback_price != null) ? product.product_prices[0].cashback_price : 0)
  };
  console.log('selectedQuantity', orderDetail);

  return orderDetail
}
export function addPackageOrderDetail(store, product) {
  let orderDetail = {
    store: Object.assign({}, store),
    product: Object.assign({}, product),
    product_id: product.id,
    quantity: product.product_price.selectedQuantity,
    order_detail_cost: product.product_price.selectedQuantity * product.product_price.price,
    cashback: product.product_price.selectedQuantity * ((product.product_prices && product.product_prices[0].cashback_price != null) ? product.product_prices[0].cashback_price : 0)
  };
  return orderDetail
}
