<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="default-dt">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="title129">  
                <h2>About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="life-gambo">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="default-title left-text">
                <h2>About Bijlee Box</h2>
                <p>Customers Deserve Better</p>
                <img src="images/line.svg" alt="">
              </div>
              <div class="about-content">
                <div v-html="settings.about"></div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-img">
                <img src="images/about.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  data () {
    return {
      settings: {
      }
    }
  },
  async mounted() {
    let settings = await axios.get('settings')
    this.settings = settings.data.data[0]
  }
}
</script>