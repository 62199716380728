<template>
  <div class="col-lg-9 col-md-8">
    <div class="dashboard-right">
      <div class="row">
        <div class="col-md-12">
          <div class="main-title-tab">
            <h4><i class="uil uil-box"></i>My Orders</h4>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="pdpt-bg"
            v-for="(order, o) in orders"
            :key="`order${o}`"
          >
            <div class="order-body10">
              <ul class="order-dtsll">
                <li>
                  <div class="order-dt-img">
                    <img src="images/groceries.svg" alt="">
                  </div>
                </li>
                <li>
                  <div class="order-dt47">
                    <h4>{{ (order.company ? order.company.name : '') }}</h4>
                    <!-- <p>Delivered - Gambo</p> -->
                    <div class="order-title">{{ order.order_details.length }} Items</div>
                  </div>
                </li>
              </ul>
              <div class="total-dt">
                <div class="total-checkout-group">
                  <div class="cart-total-dil"
                    v-for="(detail, o) in order.order_details"
                    :key="`detail${o}`"
                  >
                    <h4>{{ (detail.product ? detail.product.name : '') }} | {{ detail.product_pack }} | Qty: {{ detail.quantity }} </h4>
                    <span>₹ {{ parseFloat(detail.order_detail_cost) }}</span>
                  </div>
                </div>
              </div>
              <div class="total-dt">
                <div class="total-checkout-group">
                  <div class="cart-total-dil">
                    <h4><b>Sub Total</b></h4>
                    <span>₹ {{ parseFloat(order.order_cost) }}</span>
                  </div>
                  <div class="cart-total-dil">
                    <h4>Coupon</h4>
                    <span>₹ {{ parseFloat(order.coupon_amount) }}</span>
                  </div>
                  <div class="cart-total-dil">
                    <h4>Tax</h4>
                    <span>₹ {{ parseFloat(order.tax_amount) }}</span>
                  </div>
                  <div class="cart-total-dil">
                    <h4>Packaging</h4>
                    <span>₹ {{ parseFloat(order.packaging_amount) }}</span>
                  </div>
                  <div class="cart-total-dil ">
                    <h4>Wallet Amount Used</h4>
                    <span>₹ {{ order.wallet_amount_used }}</span>
                  </div>
                  <div class="cart-total-dil pt-3">
                    <h4>Delivery Charges</h4>
                    <span>₹ {{ order.delivery_amount }}</span>
                  </div>
                  <div class="cart-total-dil">
                    <h4>Tip</h4>
                    <span>₹ {{ order.tip_amount }}</span>
                  </div>
                </div>
                <div class="main-total-cart">
                  <h2>Total Payable</h2>
                  <span>₹ {{ (parseFloat(order.order_cost) + parseFloat(order.tax_amount) - parseFloat(order.wallet_amount_used) + parseFloat(order.delivery_amount) + parseFloat(order.packaging_amount) + parseFloat(order.tip_amount)).toFixed(2) }}</span>
                </div>
              </div>
              <div class="track-order">
                <h4>Track Order</h4>
                <div class="bs-wizard" style="border-bottom:0;">   
                  <div class="bs-wizard-step complete">
                    <div class="text-center bs-wizard-stepnum">Order Placed</div>
                    <div class="progress"><div class="progress-bar"></div></div>
                    <a href="#" class="bs-wizard-dot"></a>
                  </div>
                  <div class="bs-wizard-step complete"
                    v-for="(status, o) in order.order_statuses"
                    :key="`status${o}`"
                  >
                    <div class="text-center bs-wizard-stepnum">{{ (status.status ? status.status.name : '') }}</div>
                    <div class="progress"><div class="progress-bar"></div></div>
                    <a href="#" class="bs-wizard-dot"></a>
                  </div>
                </div>
              </div>
              <div class="alert-offer"
                v-if="order.total_cashback"
              >
                <img src="images/ribbon.svg" alt="">
                Cashback of ₹ {{ order.total_cashback }} will be credit to Foodor wallet within 6-12 hours of delivery.
              </div>
              <!-- <div class="call-bill">
                <div class="delivery-man">
                  Delivery Boy - <a href="#"><i class="uil uil-phone"></i> Call Us</a>
                </div>
              </div> -->
            </div>
          </div>
        </div>                
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      orders: []
    }
  },
  computed: {
    ...mapGetters({
      'user': 'auth/user'
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let orders = await axios.get(`/orders?userId=${this.user.id}`, {
        headers: {
          'company-id': 1
        }
      })
      this.orders = orders.data.data
    }
  }
}
</script>