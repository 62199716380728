<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Order Placed</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="all-product-grid">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
              <div class="order-placed-dt">
                <i class="uil uil-check-circle icon-circle"></i>
                <h2 v-if="recentOrder.status == 'TXN_SUCCESS'">Order Successfully Placed</h2>
                <p v-if="recentOrder.status == 'TXN_SUCCESS'">Thank you for your order! </p>
                <h2 v-else>Order Failed</h2>
                <div class="delivery-address-bg"
                  v-if="$route.query.orderId"
                >
                  <div class="title585">
                    <div class="pln-icon"><i class="uil uil-telegram-alt"></i></div>
                    <h4>Your order details</h4>
                  </div>
                  <ul class="address-placed-dt1">
                    <li>
                      <p><i class="uil uil-card-atm"></i>
                        Order ID :<span>{{ recentOrder.id }}</span>
                      </p>
                    </li>
                    <li>
                      <p><i class="uil uil-card-atm"></i>
                        Payment Status :<span>{{ recentOrder.status }}</span>
                      </p>
                    </li>
                    <li>
                      <p><i class="uil uil-card-atm"></i>
                        Response :<span>{{ recentOrder.respmsg }}</span>
                      </p>
                    </li>
                    <li>
                      <button class="btn btn-primary" @click="goToPayment">Please try again</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  data() {
    return {
      recentOrder: {
        status: ''
      }
    }
  },
  mounted() {
    this.getRecentOrder()
  },
  methods: {
    async getRecentOrder(){
      if(this.$route.query.orderId) {
        let recentOrder = await axios.get(`/orders/${this.$route.query.orderId}`)
        this.recentOrder = recentOrder.data.data
      } else {
        this.recentOrder.status = 'TXN_SUCCESS'
      }
      if(this.recentOrder.status == 'TXN_SUCCESS') {
          this.$store.dispatch('order/emptyOrder')
        }
    },
    goToPayment() {
      this.$router.push('/payment')
    }
  }
}
</script>