import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import city from './city'
import category from './category'
import settings from './settings'
import order from './order'
import offer from './offer'
import errors from './errors'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    city,
    category,
    settings,
    order,
    offer,
    errors
  }
})
