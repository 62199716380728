<template>
  <section>
    <div class="sign-inup">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sign-form">
              <div class="sign-inner">
                <div class="sign-logo" id="logo">
                  <img src="images/logo.svg" alt="" />
                  <img class="logo-inverse" src="images/dark-logo.svg" alt="" />
                </div>
                <div class="form-dt">
                  <div class="form-inpts checout-address-step">
                    <form @submit.prevent="login">
                      <div class="form-title">
                        <h6>Sign In</h6>
                      </div>
                      <span style="color: red">{{ errors.email }}</span>
                      <div class="form-group pos_rel">
                        <input id="phone[number]" name="phone" type="text" placeholder="Enter email"
                          class="form-control lgn_input" required="" v-model="form.email" />
                        <i class="uil uil-mobile-android-alt lgn_icon"></i>
                      </div>
                      <div class="form-group pos_rel">
                        <input id="password1" name="password1" type="password" placeholder="Enter Password"
                          class="form-control lgn_input" required="" v-model="form.password" />
                        <i class="uil uil-padlock lgn_icon"></i>
                      </div>
                      <button class="login-btn hover-btn" type="submit">
                        Sign In Now
                      </button>
                      <span v-if="loading">Logging In...</span>
                    </form>
                  </div>
                  <div class="password-forgor">
                    <router-link to="/forgot-password">Forgot Password?</router-link>
                  </div>
                  <div class="signup-link">
                    <p>
                      Don't have an account? -
                      <router-link to="/register">Sign Up Now</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import axios from "axios";
// import platform from 'platform';
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      errors: "errors/getErrors",
    }),
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async login() {
      this.loading = true;
      let _this = this;
      const ipInfo = await axios.get("https://ipinfo.io/json");
      const deviceInfo = navigator.userAgent;
      this.form.device_name = this.getDeviceName(deviceInfo);
      this.form.location = ipInfo.data.city;
      this.form.login_time = new Date().toLocaleString();
      this.logIn(this.form)
        .then(() => {
          if (Object.keys(this.errors) == 0) {
            _this.$router.back();
          }
          this.loading = false;
        })
        .catch(() => {
          if (Object.keys(this.errors) == 0) {
            _this.$router.back();
          }
          this.loading = false;
        });
    },
    getDeviceName(userAgent) {
      if (userAgent.match(/(iPhone|iPod|iPad)/)) {
        return "Apple Device";
      } else if (userAgent.match(/Android/)) {
        return "Android Device";
      } else if (userAgent.match(/Windows/)) {
        return "Windows PC";
      } else if (userAgent.match(/Macintosh/)) {
        return "Macintosh";
      } else {
        return "Unknown Device";
      }
    },
  },
};
</script>
