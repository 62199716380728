<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="default-dt">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="title129">  
                <h2>Refund and Return Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="life-gambo">
        <div class="container">
          <div class="row">
            <div v-html="settings.return"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  data () {
    return {
      settings: {
      }
    }
  },
  async mounted() {
    let settings = await axios.get('settings')
    this.settings = settings.data.data[0]
  }
}
</script>