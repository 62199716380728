<template>
    <div style="display: flex; background: white; width: 800px;">
        <div style="flex: 1;  border-right: 1px solid #00000040;" v-if="categories.length">
            <div v-for="(subcategory, i) in categories[0].sub_categories_filter" :key="i"
                class="sub-category-class-hover" @mouseover="getSub2Categories(subcategory.id)">
                <span><img style="width: 30px;" class="mr-2" :src="`${mediaURL}${subcategory.imagepath}`" /> {{
            subcategory.name
        }}</span>
                <span class="font-weight-bold arrow">&gt;</span>
            </div>
        </div>
        <div style="flex: 1; border-right: 1px solid #00000040;" v-if="sub_categories.length">
            <a v-for="(sub2category, i) in sub_categories[0].sub_2_categories" :key="i"
                :href="`/categories/1/stores/1?sub_category_id=${sub_categories[0].id}&sub_2_category_id=${sub2category.id}`"
                class="sub-category-class-hover" @mouseover="getSub3Categories(sub2category.id)">
                <span><img style="width: 80px;" class="mr-2" :src="`${mediaURL}${sub2category.imagepath}`" />{{
            sub2category.name }}</span>
                <span class="font-weight-bold arrow">&gt;</span>
            </a>
        </div>
        <div style="flex: 1; border-right: 1px solid #00000040;" v-if="sub_2_categories.length">
            <a v-for="(sub3category, i) in sub_2_categories[0].sub_3_categories" :key="i"
                :href="`/categories/1/stores/1?sub_category_id=${sub_categories[0].id}&sub_3_category_id=${sub3category.id}`"
                class="sub-category-class-hover">
                <span><img style="width: 30px;" class="mr-2" :src="`${mediaURL}${sub3category.imagepath}`" />{{
            sub3category.name }}</span>
            </a>
        </div>
    </div>
</template>


<script type="text/javascript">
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            sub_categories: [],
            sub_2_categories: [],
        };
    },
    computed: {
        ...mapGetters({
            categories: "category/getCategories",
            mediaURL: "settings/getMediaUrl",
        }),
    },
    methods: {
        getSub2Categories(subcategoryId) {
            this.sub_categories = [];
            this.sub_2_categories = [];
            const selectedSubcategory = this.categories[0].sub_categories_filter.find(
                subcategory => subcategory.id === subcategoryId
            );
            if (selectedSubcategory) {
                this.sub_categories.push(selectedSubcategory);
            }
        },
        getSub3Categories(sub2categoryId) {
            this.sub_2_categories = [];
            const selectedSub2category = this.sub_categories[0].sub_2_categories.find(
                sub2category => sub2category.id === sub2categoryId
            );
            if (selectedSub2category) {
                this.sub_2_categories.push(selectedSub2category);
            }
            console.log(this.sub_2_categories);
        }
    }
};
</script>

<style scoped>
.sub-category-class-hover {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    text-align: start;
    cursor: pointer;
    transition: background 0.3s ease;
    color: black;
    text-decoration: none;
}

.sub-category-class-hover .arrow {
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.3s ease;
}

.sub-category-class-hover:hover .arrow {
    opacity: 1;
    /* Visible on hover */
}

.sub-category-class-hover:hover {
    background: rgba(0, 0, 0, 0.25);
    color: white;
}
</style>
