import axios from 'axios'

export default {
  namespaced: true,
  state: {
    city: {
      id: '',
      name: ''
    },
    cities: []
  },
  getters: {
    getCities(state) {
      return state.cities
    },
    getCity(state) {
      return state.city
    }
  },
  mutations: {
    SET_CITIES(state, data) {
      state.cities = data
    },
    SET_CITY(state, city) {
      state.city = city
      localStorage.setItem('city', JSON.stringify(city))
    }
  },
  actions: {
    async fetchCities({ commit }) {
      let response = await axios.get('cities')
      console.log(response)
      commit('SET_CITIES', response.data.data)
    },
    async fetchCity({ commit }) {
      let city = localStorage.getItem('city') ? JSON.parse(localStorage.getItem('city')) : 
      {
        id: '',
        name: ''
      }
      commit('SET_CITY', city)
    },
    async setCity({ commit }, city) {
      commit('SET_CITY', city)
    }
  }
}